import Api from '../global/api';

export function notificationsLoad () {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'NOTIFICATIONS_LOAD',
            status: 'LOADING',
            payload: null
        })

        Api.req(`/notifications`, 'GET').then(
            res => {
                dispatch({
                    type: 'NOTIFICATIONS_LOAD',
                    status: 'OK',
                    payload: res.result
                })
            },
            err => {
                // Login error
                dispatch({
                    type: 'NOTIFICATIONS_LOAD',
                    status: 'error',
                    payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to load your notification settings'
                })
            }
        )        

    }
}

export function notificationsUpdate (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'NOTIFICATIONS_UPDATE',
            status: 'OK',
            payload: payload
        })
        
    }
}