import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, Button } from '@material-ui/core';
import images from '../assets/imgs';
import { useTranslation } from 'react-i18next';
import { localeCurrency } from '../global/helpers';
import ProgressButton from '../components/progressButton';
import globalContext from '../context/globalContext';
import PowerSwitch from '../components/powerSwitch';
import Api from '../global/api';
import NotInterestedIcon from '@material-ui/icons/NotInterested'

const useStyles = makeStyles(theme => ({
    serviceMeter: {
        height: 100,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0px'
    },
    serviceMeterValue: {
        fontWeight: 600,
        lineHeight: 1
    },
    serviceMeterUnit: {
        fontWeight: 600,
        fontSize: 12
    }
}));

const ServiceOngoing = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const {t} = useTranslation();

    const [error, setError] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [service, setService] = useState(null);

    const [confirmEnd, setConfirmEnd] = useState(false);
    var confirmTimer;


    useEffect(() => {

        if(typeof context.services.data !== 'undefined' && context.services.data !== null){
            const service = context.services.data.find(s => s.id_service === props.id_service)
            setService(service);
        }

    }, [context.services, props.id_service])


    const getMeterDisplay = service => {
        switch(parseInt(service.id_unit)){
            case 1:
            case 2:
                return (parseFloat(service.meter)/1000).toFixed(2).toString()
            default:
                return parseFloat(service.meter).toFixed(2).toString()
        }
    }

    const getStatus = () => {

        let state = ''
        if(service.utility.online)
          state = t('ONLINE');
        else
          state = t('OFFLINE');

        if(service.utility.breakerOk === false)
            state = state + ' / ' + t('TRIPPED')
        
        return state;
    
    }

    const isPowerOn = () => {
        return (Boolean(service.paused) === false && service.paused !== null);
    }


    const handleSwitchChange = async (e) => {

        try {
            //setWaiting(true);
            //setError(false);

            setService({
                ...service,
                paused: !e.target.checked
            })

            const result = await Api.req(`/service/${e.target.checked ? 'unpause' : 'pause'}`, 'put', service);
            console.log(result);

            if(result.success){

                context.serviceUpdate(result.result)

            } else
                throw result.msg;


        } catch (error) {
            console.log('error');
            //setError(typeof error === 'string' ? error : 'ERR');
        } finally { 
            console.log('finished');
            //setWaiting(false);
        }

    }


    const handleStopService = async () => {

        try {
            if(typeof confirmTimer !== undefined)
                clearTimeout(confirmTimer);

            setWaiting(true);
            setError(false);

            const result = await Api.req(`/service/stop`, 'POST', service);
            console.log(result);

            if(result.success){

                if(typeof props.handleEndSuccess === 'function')
                    props.handleEndSuccess();

            } else
                throw result.msg;


        } catch (error) {
            console.log('error');
            setError(typeof error === 'string' ? error : 'ERR');
        } finally { 
            console.log('finished');
            setWaiting(false);
        }

    }

    useEffect(() => {
        if(confirmEnd === true)
            confirmTimer = setTimeout(() => {
                setConfirmEnd(false)
            }, 4000)
    }, [confirmEnd])

    return (
        <React.Fragment>
            <div className="flex-col" style={{paddingBottom: 15}}>
                
                {(service !== null && service.utility !== null) &&

                    <React.Fragment>

                        <div className="flex-row p-b align-center">
                            <div className="flex-row-start gap-0 align-center">
                                <img src={parseInt(service.utility.id_utility_type) === 2 ? images.Icons_Water_Icon : images.Icons_Electric_Icon} style={{height: 20, width: 'auto', marginRight: 5}} alt="Utility type" />
                                <Typography color="primary" variant="body2">{parseInt(service.utility.id_utility_type) === 2 ? t('WATER') : t('ELECTRICITY')}</Typography>
                            </div>
                            <Typography variant="body2">{service.utility.identity}</Typography>
                        </div>

                        <div className="flex-row p-b align-center">
                            <Typography variant="caption">Status:<br /><b>{getStatus()}</b></Typography>
                            <PowerSwitch checked={isPowerOn()} handleSwitchChange={handleSwitchChange} />
                        </div>

                        <div className={classes.serviceMeter}>
                            <Typography variant="caption">{t('CURRENT_USAGE')}</Typography>
                            <div>
                                <span className={classes.serviceMeterValue} style={{fontSize: (70-(4*getMeterDisplay(service).length))}}>{getMeterDisplay(service)}</span>
                                <span className={classes.serviceMeterUnit}>{service.unit}</span>
                            </div>
                        </div>

                        <div className="flex-col gap-0">
                            {parseInt(service.utility.id_utility_type) === 1 && <Typography variant="body2" gutterBottom={true}>{t('PLUG')}: <b>{service.utility.connected ? t('CONNECTED') : t('NOT_CONNECTED')}</b></Typography>}
                            {parseInt(service.utility.id_utility_type) === 1 && 
                                <div className="flex-row-start align-center gap-10" style={{marginBottom: '0.35em'}}>
                                    <Typography variant="body2" gutterBottom={true} style={{marginBottom: 0}}>{t('BREAKER')}: <b>{service.utility.breakerOk ? t('HEALTHY') : t('TRIPPED')}</b></Typography>
                                    {!service.utility.breakerOk && <img src={images.Icons_Warning_Icon} alt="Service fault" style={{width: 24}} />}
                                </div>
                            }
                            <Typography variant="body2" gutterBottom={true}>{t('METER')}: <b>{service.meter}</b></Typography>
                            <Typography variant="body2" gutterBottom={true}>{t('TYPE')}: <b>{service.utility.utility_description}</b></Typography>
                            <Typography variant="body2" gutterBottom={true}>{t('TARIFF')}: <b>{localeCurrency(service.utility.rate, service.utility.currency)} / {service.utility.unit}</b></Typography>
                        </div>

                        <div className="flex-col p-b gap-0">
                            <Typography variant="subtitle2" gutterBottom={true}>{service.utility.organisation_name}</Typography>
                            <Typography variant="body2">{service.utility.organisation_address}</Typography>
                        </div>

                        {service.id_app_user === context.user.profile.user.id_user && 
                        <div className="flex-col align-center">
                            <div>
                                {!confirmEnd && <Button variant="contained" color="secondary" onClick={() => setConfirmEnd(true)}><NotInterestedIcon style={{fontSize: 18, marginRight: 6}} />{t('END_SERVICE')}</Button>}
                                {confirmEnd && <ProgressButton waiting={waiting} variant="contained" color="secondary" onClick={handleStopService}><NotInterestedIcon style={{fontSize: 18, marginRight: 6}} />{t('PRESS_AGAIN_TO_CONFIRM')}</ProgressButton>}
                            </div>
                        </div>
                        }

                    </React.Fragment>

                }

            </div>
        </React.Fragment>
    )
}
export default ServiceOngoing;