import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, IconButton, CircularProgress } from '@material-ui/core';
import globalContext from '../context/globalContext';
import { useTranslation } from 'react-i18next';
import { localeCurrency } from '../global/helpers';
import images from '../assets/imgs';
import ProgressButton from '../components/progressButton';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
    balance_subtitle: {
      marginBottom: 5,
      fontWeight: 400
    },
    balance_amount: {
      fontWeight: 600,
      marginBottom: 0,
      lineHeight: 1
    },
    balance_currency: {
      fontWeight: 400,
      marginBottom: 0,
      lineHeight: 1.8
    }
}));

const ProfileReturnBalance = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);
    const {t} = useTranslation();

    const [waiting, setWaiting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const handleRefund = async () => {
        setWaiting(true);
        setSuccess(false);
        setError(null);

        try {
            
            const req = await API.req('/wallet/refund', 'GET');
            if(Boolean(req.success) === true){
                setSuccess(true);
            }else
                setError(req.result.msg)

            context.walletLoad();

        } catch (error) {
            console.log(error);
            setError(error)
        } finally {
            setWaiting(false);
        }
    }


    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inner}>

                    {success && 
                        <div className="flex-col align-center">
                            <img src={images.Icons_Tick_Blue_icon} style={{width: 140, height: 'auto'}} alt={t('SUCCESS')} />
                            <Typography variant="h5">{t('SUCCESS')}</Typography>
                            <Typography>{t('YOUR_RETURN_BALANCE_HAS_BEEN_ISSUED')}</Typography>
                        </div>
                    }

                    {!success && 
                        <React.Fragment>

                            <div className="flex-col align-center p-b p-t">
                                <img src={images.Icons_Withdraw_Icon} alt="Help" style={{width: 80}}/>
                                <Typography variant="body2" className={classes.balance_subtitle}>{t('RETURN_BALANCE_ONE')}</Typography>

                                {error && <Alert severity="error">{t(error)}</Alert>}
                            </div>

                            <div className="flex-col align-center gap-0">

                                <Typography variant="subtitle2" className={classes.balance_subtitle}>{t('YOUR_AVAILABLE_RETURN_BALANCE_IS')}</Typography>
                                <Typography variant="h2" className={classes.balance_amount}>{context.wallet.status === 'LOADING' ? <CircularProgress size={24} />: localeCurrency(context.wallet.data.balance, context.wallet.data.currency)}</Typography>
                                {context.wallet.status !== 'LOADING' && <Typography variant="subtitle2" className={classes.balance_currency}>({context.wallet.data.currency})</Typography>}
                                
                            </div>

                            <div className="flex-col align-center p-b p-t">
                                <ProgressButton waiting={waiting} onClick={handleRefund} variant="contained">Confirm</ProgressButton>
                            </div>

                        </React.Fragment>
                    }

                </div>
            </div>
        </React.Fragment>
    )
}
export default ProfileReturnBalance;