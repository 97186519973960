import React, {useState, useEffect, useRef} from 'react';
import { useTheme, Typography, makeStyles, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import InputField from '../components/inputField';
import { makeValidation, isValidForm} from'../global/formHelper';
import clsx from 'clsx';
import SlideOverDialog from '../components/slideOverDialog';
import ServiceAvailability from './serviceAvailability';
import ElevateOverNotify from '../components/elevateOverNotify';
import images from '../assets/imgs';
import InfoIcon from '@material-ui/icons/InfoOutlined'

const useStyles = makeStyles(theme => ({
    searchButton: {
        height: 56,
        width: 56,
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 12,
        backgroundColor: '#ffffff', 
        margin: '6px 0 20px 0',
        transition: 'all .3s ease',
        position: 'relative',
        overflow: 'hidden',
        '& span.ripple': {
            position: 'absolute',
            borderRadius: '50%',
            transform: 'scale(0)',
            animation: '$ripple 400ms linear',
            backgroundColor: 'rgba(255, 255, 255, 0.3)'
        }
    },
    "@keyframes ripple": {
        "to": {
          transform: 'scale(4)',
          opacity: 0
        }
    },
    searchButtonValid: {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
    },
    searchButtonIcon: {
        width: 32,
        height: 32
    },
    searchButtonLine: {
        fill: 'none',
        stroke: theme.palette.grey[400],
        strokeLinecap:'round',
        strokeLinejoin:'round',
        strokeWidth: 1
    },
    searchButtonLineValid: {
        stroke: '#ffffff',
        strokeWidth: 1.6
    }
}));

const ServiceSearch = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    
    const {t} = useTranslation();
    const [form, setForm] = useState({search: ''});
    const [error, setError] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [validation, setValidation] = useState(makeValidation([
        {
            name: 'search',
            display_name: t('SERVICE_ID'),
            required: true
        },
    ]));

    const [showAvailability, setShowAvailability] = useState(false);
    const [serviceStarted, setServiceStarted] = useState(false);

    const [showHelp, setShowHelp] = useState(false);

    useEffect(() => {
        const valid = isValidForm(form, validation, false);
        setValidation(valid.validation);
        setIsValid(valid.isValid);
    }, [form])
    
    const handleInputChange = e => {
        let newForm = {...form};
        newForm[e.target.name] = e.target.value.toUpperCase();
        setForm(newForm);
    }


    const searchButton = useRef(null);
    const createRipple = (event) => {
        console.log('fire fire')

        const button = event.currentTarget;
      
        const circle = document.createElement("span");
        const diameter = Math.max(button.clientWidth, button.clientHeight);
        const radius = diameter / 2;
      
        circle.style.width = circle.style.height = `${diameter}px`;
        circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
        circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
        circle.classList.add("ripple");
      
        const ripple = button.getElementsByClassName("ripple")[0];
      
        if (ripple) {
          ripple.remove();
        }
      
        button.appendChild(circle);
    }

    useEffect(() => {
        if(searchButton.current !== null)
            searchButton.current.addEventListener("click", createRipple);
    }, [searchButton])


    const handleServiceStartSuccess = () => {
        setShowAvailability(false);
        setServiceStarted(true);
        let newForm = {...form};
        let newValidation = {...validation};
        newForm.search = '';
        newValidation.search.dirty = false;
        setForm(newForm);
        setValidation(newValidation);
    }
    

    return (
        <React.Fragment>
            <div>
                <div className="flex-row-start align-center gap-10">
                    <Typography variant="subtitle2">{t('START_A_NEW_SERVICE')}</Typography>
                    <IconButton size="small" onClick={() => setShowHelp(true)}><InfoIcon color="primary" style={{fontSize: 20}} /></IconButton>
                </div>

                {error && <Alert severity='error'>{t(error)}</Alert>}
                
                <div className="flex-row gap-10 justify-center">
                    <InputField name='search' validation={validation.search} value={form.search} handleChange={handleInputChange} noLabel={true} placeholder='XX-XXXX-XXXXX' />
                    <button ref={searchButton} className={clsx([classes.searchButton, form.search !== '' && classes.searchButtonValid])} onClick={() => setShowAvailability(true)}>
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className={classes.searchButtonIcon}>
                            <line x1="15" y1="5" x2="15" y2="25" className={clsx([classes.searchButtonLine, form.search !== '' && classes.searchButtonLineValid])}/>
                            <line x1="25" y1="15" x2="5" y2="15" className={clsx([classes.searchButtonLine, form.search !== '' && classes.searchButtonLineValid])}/>
                        </svg>
                    </button>
                </div>
            </div>

            <SlideOverDialog show={showAvailability} title={t('SERVICE')} titleVariant="h6" handleClose={() => setShowAvailability(false)} color="primary"><ServiceAvailability identity={form.search} handleClose={() => setShowAvailability(false)} handleSuccess={handleServiceStartSuccess} /></SlideOverDialog>
        
            <ElevateOverNotify show={serviceStarted} handleClose={() => setServiceStarted(false)} button={t('OK')} >
                <div className="flex-col align-center">
                    <img src={images.Icons_Tick_icon} style={{width: 140, height: 'auto'}} alt={t('YOU_ARE_NOW_USING_THIS_SERVICE')} />
                    <Typography variant="h5">{t('SUCCESS')}</Typography>
                    <Typography>{t('YOU_ARE_NOW_USING_THIS_SERVICE')}</Typography>
                </div>
            </ElevateOverNotify>


            <ElevateOverNotify show={showHelp} handleClose={() => setShowHelp(false)} button={t('OK')} >
                <div className="flex-col align-center">
                    <img src={images.Icons_Help_Icon} style={{width: 140, height: 'auto'}} alt={t('HELP_SERVICE_SEARCH')} />
                    <Typography variant="h5">{t('HELP')}</Typography>
                    <Typography>{t('HELP_SERVICE_SEARCH')}</Typography>
                </div>
            </ElevateOverNotify>
        
        </React.Fragment>
    )
}
export default ServiceSearch;