import React from 'react';
import {AppBar, IconButton, makeStyles, Toolbar, useTheme} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import images from '../assets/imgs';

const useStyles = makeStyles(theme => ({
    menuButton: {

    },
    logo: {
        height: 32,
        width: 'auto'
    }
}))


const MainToolbar = props => {

    const theme = useTheme();
    const classes = useStyles(theme);

    const handleShowMenu = () => {
        if(typeof props.handleShowMenu === 'function')
            props.handleShowMenu();
    }

    return (
        <AppBar position="sticky" color="default" elevation={0} style={{zIndex: 899}}>
            <Toolbar>
                <img src={images.logo} alt="BerthVend Marina Services" className={classes.logo} />
                <div className="flex"></div>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleShowMenu}>
                    <MenuIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}

export default MainToolbar