import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core';

import {osName, browserName} from "react-device-detect";
import API from './global/api';

import { userValidate } from './actions/userActions';

import Signin from './context/signin/signin';
import ResetPassword from './context/signin/resetPassword';
import Main from './context/main';

import Home from './routes/home';
import NotFound from './routes/notFound';
import Profile from './routes/profile';
import Marinas from './routes/marinas';
import History from './routes/history';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    overflow: 'hidden',
    position: 'relative'
  }
}))



const App = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  

  //
  //  Device information
  //

  const device = useRef({
    checked: false,
    id_user: null,
    id_device: null,
    device_os: osName,
    device_make: browserName,
    device_model: null,
    push_token: null,
    app_version: process.env.REACT_APP_VERSION || '0.0.0',
    app_type: process.env.REACT_APP_NATIVE == 'true'?'NATIVE':'WEB',
  });

  const [deviceState, setDeviceState] = useState(device.current);

  const setDevice = data => {
    // console.log('Set device to');
    // console.log(data);
    device.current = data;
    setDeviceState(data);
  }

  


  useEffect(() => {

    const _logDeviceInfo = async () => {

      // console.log('Device changed to');
      // console.log(device.current);

      //
      //  In order to log the device information we need to ensure we have
      //  checked local storage for an existing device id first and check if
      //  the user is logged in
      //
      if(device.current.checked && device.current.id_user !== null)
        try {
          const logDevice = await API.req(`/user/device`, 'POST', device.current);
          if(logDevice.result.id_device !== null)
            API.setLocal(process.env.REACT_APP_DEVICE_ID || 'did', logDevice.result.id_device);
    
        } catch (error) {
    
          console.log(error);
          console.log('Device not logged')
          
        }
  
    }
    _logDeviceInfo();

  }, [deviceState])

  useEffect(() => {

    if(props.user.status === 'in' && device.current.id_user === null){
      // console.log('current device')
      // console.log(device.current);
      setDevice({
        ...device.current,
        id_user: props.user.profile.user.id_user
      })
    }

  }, [props.user])

  


  useEffect(() => {

    /*
    *
    * Native Message Handler
    *
    */

    /*
    *
    * Event listner for messages coming in
    *
    */
    const nativeMessge = (e) => {
      var message;
      try {
        message = JSON.parse(e.data)
        switch (message.targetFunc.toUpperCase()) {
          case "CONNECT": 
            // If the message was to connect
            // return a response of connected
            if(typeof window.webViewBridge !== 'undefined')
              window.webViewBridge.send('connected', {msg: 'connected'}, true, false);
            break;
          case "DEVICE":
            // Native device id takes priority and so checked is always set to true
            // console.log('current device')
            // console.log(device.current);
            setDevice({
              ...device.current,
              checked: true,
              ...message.data
            })
            break;
          case "WAKE":
            // Native device has gone into the background and returned to the foreground
            props.userValidate();
            break;
        }
      }
      catch(err) {
        console.error("failed to parse message from react-native " + err);
        return;
      }
    };

    window.addEventListener('message', nativeMessge);

    
    const _checkForDeviceId = async () => {
      const id_device = await API.getLocal(process.env.REACT_APP_DEVICE_ID || 'did');
      // console.log('current device')
      // console.log(device.current);
      setDevice({
        ...device.current,
        id_device: id_device,
        checked: true
      })
    }
    _checkForDeviceId();

    return () => {
      window.removeEventListener('message', nativeMessge);
    }
  }, [])
  


  

  return (
          
    <Router>
      <div className={classes.root}>

        {props.user.status !== 'in' && 
        
          <Switch>
              <Route path={"/reset_password/:token"} exact component={ResetPassword} ></Route>
              <Route component={Signin} />
          </Switch>
        
        }

        {props.user.status === 'in' &&
        
            <Main history={props.history}>

              <Switch>
                  <Route path={"/"} exact component={Home} ></Route>
                  <Route path={"/profile"} exact component={Profile} ></Route>
                  <Route path={"/marinas"} exact component={Marinas} ></Route>
                  <Route path={"/history"} exact component={History} ></Route>
                  <Route component={NotFound} />
              </Switch>

            </Main>
        }
      </div>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userValidate: () => {
      dispatch(userValidate());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
