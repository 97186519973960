import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { useTranslation } from 'react-i18next';

import './styles.css';
import {makeStyles, useTheme, Typography, Link, Button, CircularProgress} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { login, userValidate, register } from '../../actions/userActions';
import { makeValidation, validateForm, isValidForm } from '../../global/formHelper';

import Images from '../../assets/imgs/index';
import InputField from '../../components/inputField';
import ProgressButton from '../../components/progressButton';

import background from "../../assets/imgs/Login_Screen.png";

import Register from './register';
import ForgotPassword from './forgotPassword';

const useStyles = makeStyles(theme => ({
  signinContainer: {
    width: '100%',
    maxWidth: 360,
    margin: 'auto',
    overflow: 'auto',
    padding: 20
  },
  form: {
    marginBottom: 10
  },
  footer: {
    marginTop: 40
  },
  logo: {
    width: '80%',
    height: 'auto',
    marginBottom: 20
  },
  verifyingLoader: {
    width: '80%',
    maxWidth: 600,
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))


const Signin = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();

  const [mode, setMode] = useState('SIGNIN');

  const [form, setForm] = useState({
    username: '',
    password: ''
  })

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'email',
      display_name: t('EMAIL'),
      required: true
    },
    {
      name: 'password',
      display_name: t('PASSWORD'),
      required: true
    }
  ]));

  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  useEffect(() => {
    setWaiting((props.user.status === 'loading'));
  }, [props.user])

  useEffect(() => {
    props.userValidate();
  }, [])

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = () => {
    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid)
      props.login(form);
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter')
      handleSubmit();
  }

  const handleClickRegister = () => {
    if(mode === 'SIGNIN')
      setMode('REGISTER');
  }


  return (
    <div className="container" style={{backgroundImage: `url(${background})` }}>
        
      {props.user.verifying && <div className={classes.verifyingLoader}><CircularProgress color="primary" /></div>}

      {/* The actual content */}
      {!props.user.verifying && 
      <div className="inner">


        <div className={classes.signinContainer}>
          
          <div style={{flex: 1}}></div>
          
          {/* Sign in form */}
          <div style={{paddingTop: 20, paddingBottom: 20}}>

            <div className="flex-col align-center">
              <img src={Images.logo_with_sub} className={classes.logo} alt="BerthVend Smart Solutions" />
            </div>

            {mode === 'SIGNIN' && 
              <React.Fragment>

                {props.user.msg && <Alert severity='error'>{t(props.user.msg)}</Alert>}

                <div className={classes.form}>
                  <InputField name='email' size='large' validation={validation.email} value={form.email} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
                  <InputField name='password' size='large' type='password' validation={validation.password} value={form.password} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
                  <Typography variant="caption" align="right" display="block">{t('FORGOTTEN_YOUR_PASSWORD')} <Link onClick={() => setMode('FORGOT')} style={{cursor: 'pointer'}}><b>{t('RESET')}</b></Link></Typography>
                </div>

                <div className={classes.footer}>
                  <div className="flex-row gap-30">
                    <ProgressButton variant="contained" size="large" color="primary" fullWidth waiting={waiting} onClick={handleSubmit} gutterBottom={true}>{t('SIGN_IN')}</ProgressButton>
                    <Button onClick={handleClickRegister} fullWidth color="default" variant="contained" size="large">{t('REGISTER')}</Button>
                  </div>
                  <Typography variant="caption" align="right" display="block"><Link>{t('APP_SUPPORT')}</Link></Typography>
                </div>
              </React.Fragment>
            }

            {mode === 'REGISTER' && 
              <Register handleBack={() => setMode('SIGNIN')} user={props.user} handleRegister={props.register} />
            }

            {mode === 'FORGOT' && 
              <ForgotPassword handleBack={() => setMode('SIGNIN')} />
            }

          </div>

          <div style={{flex: 1}}></div>
        </div>

      </div>}

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (fields) => {
            dispatch(login(fields));
        },
        userValidate: () => {
            dispatch(userValidate());
        },
        register: (fields) => {
          dispatch(register(fields));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
