import React, {useState} from 'react';
import { useTheme, Typography, makeStyles, Button } from '@material-ui/core';
import images from '../assets/imgs';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import API from '../global/api';
import ProgressButton from '../components/progressButton';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: 20
    },
}));

const ProfileTransactionResendReceipt = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [waiting, setWaiting] = useState(false);


    const handleResendReceipt = async () => {
        if(props.transaction !== null){
            try {

                setWaiting(true);
                setSuccess(false);
                setError(null);

                const sendReceipt = await API.req(`/wallet/transactions/receipt/${props.transaction.id_wallet_transaction}`, 'GET');
                if(sendReceipt.success)
                    setSuccess(true);

            } catch (error) {
                setError(error);
            } finally {
                setWaiting(false);
            }
        }
    }

    const handleClose = () => {
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inner}>
                    {error && <Alert severity="error">{t(error)}</Alert>}
                    {!success && 
                        <React.Fragment>
                            <img src={images.Icons_Email_Blue_Sent_Icon} style={{width: 120, height: 120}} alt="Email receipt" />
                            <Typography className="p-b" align="center">{t('PRESS_SEND_TO_RESEND_THIS_RECEIPT_TO_YOUR_EMAIL')}</Typography>
                            <ProgressButton waiting={waiting} variant="contained" onClick={handleResendReceipt}>{t('SEND')}</ProgressButton>
                        </React.Fragment>
                    }
                    {success && 
                        <React.Fragment>
                            <img src={images.Icons_Tick_Blue_icon} style={{width: 120, height: 120}} alt="Email sent" />
                            <Typography className="p-b" align="center">{t('YOUR_RECEIPT_HAS_BEEN_SENT')}</Typography>
                            <Button variant="contained" onClick={handleClose}>{t('CLOSE')}</Button>
                        </React.Fragment>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
export default ProfileTransactionResendReceipt;