import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, Button, Collapse  } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import InputField from '../components/inputField';
import ProgressButton from '../components/progressButton';
import { makeValidation, isValidForm} from'../global/formHelper';
import Api from '../global/api';
import { useTranslation } from 'react-i18next';
import globalContext from '../context/globalContext';
import { isNull, ISOCountryCodes, localeCurrency } from '../global/helpers';

const useStyles = makeStyles(theme => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    }
}));

const ProfileTopUp = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);
    const {t} = useTranslation();

    const amountPresets = [10,20,50,75,100];
    const [amount, setAmount] = useState(10);

    const [paymentMethods, setPaymentMethods] = useState([{
        key: false,
        value: 'New card'
    }])

    const [view, setView] = useState('select');
    const [paymentUrl, setPaymentUrl] = useState(null);

    const [form, setForm] = useState({
        amount: 0,
        first_name: '',
        last_name: '',
        address1: '',
        locality: '',
        postal_code: '',
        country_code: '',
        repeat: false
    });

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const [validation, setValidation] = useState(makeValidation([
        {
            name: 'amount',
            display_name: t('AMOUNT'),
            required: false
        },
        {
            name: 'first_name',
            display_name: t('FIRST_NAME'),
            required: true
        },
        {
            name: 'last_name',
            display_name: t('LAST_NAME'),
            required: true
        },
        {
            name: 'address1',
            display_name: t('ADDRESS1'),
            required: true
        },
        {
            name: 'locality',
            display_name: t('LOCALITY'),
            required: true
        },
        {
            name: 'postal_code',
            display_name: t('POSTAL_CODE'),
            required: false
        },
        {
            name: 'country_code',
            display_name: t('COUNTRY'),
            required: true
        },
        {
            name: 'repeat',
            display_name: t('PAYMENT_METHOD'),
            required: true
        }
    ]));
    
    useEffect(() => {
        const valid = isValidForm(form, validation, false);
        setValidation(valid.validation);
        setIsValid(valid.isValid || form.repeat === true);
    }, [form])
    
    const handleInputChange = e => {
        let newForm = {...form};
        newForm[e.target.name] = e.target.value;
        setForm(newForm);

        if(e.target.name === 'amount')
            if(isNaN(parseFloat(e.target.value)))
                setAmount(10);
            else
                setAmount(parseFloat(e.target.value));
    }

    const handleAmountChange = a => {
        setAmount(parseFloat(a));
        let newForm = {...form};
        newForm.amount = '';
        setForm(newForm);
    }

    const handleSelect = () => {
        if(amount !== null || !isNaN(parseFloat(amount)))
            setView('confirm');
    }
    
    const handleSubmit =  async () => {
        const valid = isValidForm(form, validation);
        setValidation(valid.validation);

        if(valid.isValid || (form.repeat && amount !== null)){

            setError(null);
            setWaiting(true);
            setSuccess(false);

            try {

                const pay_form = {
                    ...form,
                    amount: amount,
                    currency: context.wallet.data.currency
                }

                const result = await Api.req('/wallet', 'POST', pay_form);    
                if(result.success){
                    setPaymentUrl(result.result.paymentUrl);
                    setView('pay');
                } else
                    throw result.msg;
    
            } catch (error) {
                setError(typeof error === 'string' ? error : 'ERR');
            } finally { 
                console.log('finished');
                setWaiting(false);
            }

        }
    }

    const handlePaymentWindowChange = async () => {

        try {
            const getWallet = await Api.req('/wallet', 'GET');    
            if(getWallet.success){
                if(parseFloat(getWallet.result.balance) > parseFloat(context.wallet.data.balance)){
                    context.walletUpdate(getWallet.result);
                    setTimeout(() => {
                        if(typeof props.handleClose === 'function')
                            props.handleClose();
                    }, 1000)
                }
            } else
                throw getWallet.msg;

        } catch (error) {
            console.log(error);
            setError(typeof error === 'string' ? error : 'ERR');
        }

    }

    useEffect(() => {

        if(context.user.profile.user !== null && form.first_name === '' && form.last_name === '' && form.address1 === '' && form.locality === '' && form.postal_code === ''){
            let newForm = JSON.parse(JSON.stringify(form))
            newForm.first_name = isNull(context.user.profile.user.first_name, '');
            newForm.last_name = isNull(context.user.profile.user.last_name, '');
            newForm.address1 = `${isNull(context.user.profile.user.address1, '')} ${isNull(context.user.profile.user.address2, '')}`.trim();
            newForm.locality = isNull(context.user.profile.user.locality, '');
            newForm.postal_code = isNull(context.user.profile.user.postal_code, '');
            newForm.country_code = isNull(context.user.profile.user.country_code, '');
            setForm(newForm);
        }
        
        if(context.wallet.data !== null && paymentMethods.length === 1 && (typeof context.wallet.data.last_4digits !== 'undefined' && context.wallet.data.last_4digits !== null)){
            let newPaymentMethods = JSON.parse(JSON.stringify(paymentMethods));
            newPaymentMethods.push({
                key: true,
                value: `${context.wallet.data.card_type} **${context.wallet.data.last_4digits}`
            })
            setPaymentMethods(newPaymentMethods);
        }

    }, [context.user, context.wallet])

    useEffect(() => {

        if(paymentMethods.length > 1){
            let newForm = JSON.parse(JSON.stringify(form))
            newForm.repeat = true;
            setForm(newForm);
        }

    }, [paymentMethods])

    return (
        <React.Fragment>
            <div className={classes.container}>

                {error && <Alert severity="error" className="p-b">{t(error)}</Alert>}

                <Collapse  in={view === 'select'}>

                    <div className="p-b">
                        <Typography variant="subtitle1" align="center" className="p-b">{t('SELECT_AN_AMOUNT')}</Typography>
                        <div className="flex-row-start justify-center flex-wrap gap-10 p-b">
                            {amountPresets.map(a => (
                                <Button key={a} variant={a === amount ? 'contained' : 'outlined'} color={a === amount ? 'primary' : 'default'} size="large" onClick={() => handleAmountChange(a)}>{localeCurrency(a, context.wallet.data.currency)}</Button>
                            ))}
                        </div>
                    </div>

                    <div className="p-b">
                        <Typography variant="subtitle1" align="center">{t('OR_ENTER_AN_AMOUNT')}</Typography>
                        <div className="flex-row justify-center">
                            <div style={{width: 150}}>
                                <InputField 
                                    name="amount" 
                                    noLabel={true} 
                                    validation={validation.amount} 
                                    value={form.amount} 
                                    helperText={`${t('MINIMUM')} 
                                    ${localeCurrency(5, context.wallet.data.currency)}`} 
                                    handleChange={handleInputChange} 
                                    placeholder={localeCurrency(15, context.wallet.data.currency)}
                                />
                            </div>
                        </div>
                    </div>

                </Collapse >

                <div className="p-b">
                    <div className="flex-row">
                        <Typography>{t('CURRENT_BALANCE')}:</Typography>
                        <Typography><b>{localeCurrency(context.wallet.data.balance, context.wallet.data.currency)}</b></Typography>
                    </div>
                    <div className="flex-row">
                        <Typography>{t('TOP_UP_AMOUNT')}:</Typography>
                        <Typography><b>{localeCurrency(amount, context.wallet.data.currency)}</b></Typography>
                    </div>
                    <div className="flex-row">
                        <Typography>{t('YOUR_NEW_BALANCE_WILL_BE')}:</Typography>
                        <Typography><b>{localeCurrency((parseFloat(context.wallet.data.balance) + parseFloat(amount)), context.wallet.data.currency)}</b></Typography>
                    </div>
                </div>


                {view === 'confirm' &&
                    <div className="p-b">
                        <InputField 
                            name="repeat" 
                            hoverLabel={true} 
                            validation={validation.repeat} 
                            value={form.repeat} 
                            handleChange={handleInputChange} 
                            type="select"
                            items={paymentMethods}
                        />
                    </div>
                }

                <Collapse  in={view === 'confirm' && form.repeat === false}>

                    <div className="p-b">
                        <Typography variant="subtitle2" className="p-b">{t('CARD_HOLDER')}</Typography>

                        <div className="flex-row">
                            <InputField name='first_name' validation={validation.first_name} value={form.first_name} handleChange={handleInputChange} hoverLabel={true} />
                            <InputField name='last_name' validation={validation.last_name} value={form.last_name} handleChange={handleInputChange} hoverLabel={true} />
                        </div>
                        <InputField name='address1' validation={validation.address1} value={form.address1} handleChange={handleInputChange} hoverLabel={true}/>
                        <InputField name='locality' validation={validation.locality} value={form.locality} handleChange={handleInputChange} hoverLabel={true} />
                        <InputField name='postal_code' validation={validation.postal_code} value={form.postal_code} handleChange={handleInputChange} hoverLabel={true} />
                        <InputField name='country_code' validation={validation.country_code} value={form.country_code} handleChange={handleInputChange} hoverLabel={true} type="select" items={ISOCountryCodes()} />
                    </div>

                </Collapse>

                <div className="flex-row justify-center p-t">
                    {view === 'select' && <ProgressButton waiting={waiting} variant="contained" onClick={handleSelect} disabled={!parseFloat(amount) > 0}>{t('NEXT')}</ProgressButton>}
                    {view === 'confirm' && 
                        <React.Fragment>
                            <Button onClick={() => setView('select')}>{t('BACK')}</Button>
                            <ProgressButton waiting={waiting} variant="contained" onClick={handleSubmit} disabled={!isValid}>{t('CONFIRM')}</ProgressButton>
                        </React.Fragment>
                    }
                </div>

                {(paymentUrl !== null && view === 'pay') && 
                    <div className="flex">
                        <iframe 
                            frameBorder="0" 
                            marginHeight="0" 
                            marginWidth="0" 
                            src={paymentUrl} 
                            width="100%" 
                            height="100%" 
                            onLoad={handlePaymentWindowChange}
                        ></iframe>
                    </div>
                }

            </div>
        </React.Fragment>
    )
}
export default ProfileTopUp;