import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, List, ListItem, Avatar, CircularProgress } from '@material-ui/core';
import globalContext from '../context/globalContext';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@material-ui/icons/Check';
import API from '../global/api';
import images from '../assets/imgs';



const useStyles = makeStyles(theme => ({
    inner: {
        paddingBottom: 20
    },
    item: {
        paddingLeft: 0
    },
    avatarTick: {
        width: 24,
        height: 24,
        fontSize: 14,
        marginRight: 10
    },
    avatarTickBlue: {
        width: 24,
        height: 24,
        fontSize: 14,
        marginRight: 10,
        backgroundColor: theme.palette.primary.main
    },
    check: {
        color: theme.palette.primary.main,
        fontSize: 18,
        marginLeft: 4
    },
    infoAvatar: {
        width: 36,
        height: 36,
        backgroundColor: theme.palette.grey[200],
        '& > img': {
            width: '70%',
            height: '70%'
        }
    }
}));

const ProfileSwitchCurrency = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);
    const {t} = useTranslation();

    const [error, setError] = useState(null);
    const [waiting, setWaiting] = useState(false);

    const [currency, setCurrency] = useState('');
    const currencies = [
        {
            code: 'EUR',
            symbol: '€'
        },
        {
            code: 'GBP',
            symbol: '£'
        }
    ];

    useEffect(() => {
        if(context.wallet.data !== null)
            setCurrency(context.wallet.data.currency.toUpperCase())
    }, [context.wallet])


    const handleSwitchCurrency = async code => {

        setError(null);
        setWaiting(true);

        try {

            const result = await API.req('/wallet/currency', 'PUT', {
                currency: code
            });

            if(result.success){
                context.walletUpdate(result.result)
                if(typeof props.handleClose === 'function')
                    props.handleClose();
            } else
                throw result.msg;

        } catch (error) {
            console.log('error');
            setError(typeof error === 'string' ? error : 'ERR');
        } finally { 
            console.log('finished');
            setWaiting(false);
        }


    }


    return (
        <div className={classes.inner}>
            {parseFloat(context.wallet.data.balance) > 0 && <Typography variant="caption" color="error" component="div">{t('YOUR_CURRENCY_CAN_ONLY_BE_CHANGED_WHEN_YOUR_BALANCE_IS_ZERO')}</Typography>}
            <Typography variant="caption">{t('TAP_A_CURRENCY_TO_SELECT')}</Typography>

            <List>
                {currencies.map((c, idx) => <ListItem key={idx} button className={classes.item} onClick={() => handleSwitchCurrency(c.code)}><Avatar className={(currency === c.code && !context.wallet.data.noWallet) ? classes.avatarTickBlue :  classes.avatarTick}>{c.symbol}</Avatar>{c.code} ({c.symbol}){(currency === c.code && !context.wallet.data.noWallet) && <CheckIcon className={classes.check} />}</ListItem>)}
            </List>
            
            {context.wallet.data.noWallet && 
                <div className="flex-row-start p-t p-b">
                    <Avatar src={images.Icons_Edit_Icon} className={classes.infoAvatar} />
                    <div>
                        <Typography variant="subtitle2">{t('QUICK_TIP')}</Typography>
                        <Typography variant="body2">{t('HELP_CURRENCY_SWITCH')}</Typography>
                    </div>
                </div>
            }

            {waiting && <div className="flex-row-end">
                <Typography variant="caption">{t('PLEASE_WAIT')}</Typography>
                <CircularProgress color="primary" size={18} />
            </div>}
        </div>
    )
    
}
export default ProfileSwitchCurrency;