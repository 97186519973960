import React, {useEffect, useState} from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import {makeStyles, useTheme, Typography, Button} from '@material-ui/core';
import { makeValidation, validateForm, isValidForm } from '../../global/formHelper';
import Alert from '@material-ui/lab/Alert'

import InputField from '../../components/inputField';
import ProgressButton from '../../components/progressButton';

import API from '../../global/api';
import SlideOverNotify from '../../components/slideOverNotify';
import images from '../../assets/imgs';

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  },
  footer: {
    marginTop: 40,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  intro: {
    width: '80%', 
    marginBottom: 15
  },
  email: {
    width: '100%'
  }
}))


const ForgotPassword = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const {t}  = useTranslation();

  const [form, setForm] = useState({
    email: ''
  })

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'email',
      display_name: t('EMAIL'),
      required: true
    },
  ]));

  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = () => {
    setError(null);
    setSuccess(false);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){
      setWaiting(true);

      API.req(`/user/password/forgot`, 'POST', form).then(
        res => {
          setWaiting(false);
          if(res.success === true){
            setSuccess(true);
          } else
            setError(res.msg);
        },
        err => {
          setWaiting(false);
          setError(err)
        }
      )

    }
  }

  const handleCloseSuccess = () => {
    setSuccess(false);
  }


  return (
    <React.Fragment>
    <div style={{marginTop: 60}}>

      {error && <Alert severity='error' style={{marginBottom: 20}}>{t(error)}</Alert>}
      
      <div className={classes.form}>
        <Typography variant="caption" align="center" component="p" className={classes.intro}>{t('FORGOT_PASSWORD_INTRO')}</Typography>
        <div className={classes.email}>
          <InputField name='email' size='large' fullWidth validation={validation.email} value={form.email} handleChange={handleInputChange} />
        </div>
        <div className="flex-row" style={{width: '100%'}}>
          <Button onClick={props.handleBack}><b>{t('BACK_TO_SIGNIN')}</b></Button>
          <ProgressButton variant="contained" size="large" color="default" waiting={waiting} onClick={handleSubmit} gutterBottom={true}>{t('RESET')}</ProgressButton>
        </div>
      </div>

    </div>

    <SlideOverNotify show={success} side="right" handleClose={handleCloseSuccess} button={t('OK')} >
      <div className="flex-col align-center">
        <img src={images.Icons_Email_Sent_Icon} style={{width: 200, height: 'auto'}} alt={t('WEVE_SENT_YOU_AN_EMAIL')} />
        <Typography variant="h5">{t('WEVE_SENT_YOU_AN_EMAIL')}</Typography>
        <Typography>{t('FORGOT_PASSWORD_SUCCESS')}</Typography>
      </div>
    </SlideOverNotify>
    </React.Fragment>
  );
}

export default ForgotPassword;
