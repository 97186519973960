import { useTheme, Typography, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useContext, useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../components/inputField';
import ProgressButton from '../components/progressButton';
import globalContext from '../context/globalContext';
import { makeValidation, isValidForm} from'../global/formHelper';
import Api from '../global/api';
import ElevateOverNotify from '../components/elevateOverNotify';
import images from '../assets/imgs';
import { ISOCountryCodes } from '../global/helpers';



const useStyles = makeStyles(theme => ({
    container: {

    }, 
    inner: {

    },
    form: {
        marginTop: 20
    }
}));

const ProfileEdit = props => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);
    const {t} = useTranslation();

    const [form, setForm] = useState(context.user.profile.user);

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const [validation, setValidation] = useState(makeValidation([
        {
        name: 'first_name',
        display_name: t('FIRST_NAME'),
        required: true
        },
        {
        name: 'last_name',
        display_name: t('LAST_NAME'),
        required: true
        },
        {
        name: 'address1',
        display_name: t('ADDRESS1'),
        required: true
        },
        {
        name: 'address2',
        display_name: t('ADDRESS2'),
        required: false
        },
        {
        name: 'address3',
        display_name: t('ADDRESS3'),
        required: false
        },
        {
        name: 'locality',
        display_name: t('LOCALITY'),
        required: true
        },
        {
        name: 'province',
        display_name: t('PROVINCE'),
        required: false
        },
        {
        name: 'postal_code',
        display_name: t('POSTAL_CODE'),
        required: false
        },
        {
        name: 'country_code',
        display_name: t('COUNTRY'),
        required: false
        },
        {
        name: 'email',
        display_name: t('EMAIL'),
        required: true
        },
        {
        name: 'phone',
        display_name: t('PHONE'),
        required: true,
        additional: [
            {
              type: 'phone',
              error: t('PHONE_REQUIREMENTS')
            }
          ]
        }
    ]));

  
    useEffect(() => {
        const valid = isValidForm(form, validation, false);
        setValidation(valid.validation);
        setIsValid(valid.isValid);
    }, [form])


    const handleInputChange = e => {
        let newForm = {...form};
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handleSubmit =  async () => {
        const valid = isValidForm(form, validation);
        setValidation(valid.validation);
        if(valid.isValid){

            setError(null);
            setWaiting(true);
            setSuccess(false);
            try {
                const result = await Api.req('/user', 'PUT', form);
                console.log(result);

                if(result.success){
                    context.userUpdateProfile(result.result);
                    setSuccess(true);
                } else
                    throw result.msg;

            } catch (error) {
                console.log('error');
                setError(typeof error === 'string' ? error : 'ERR');
            } finally { 
                console.log('finished');
                setWaiting(false);
            }

        }
    }


    const handleCloseSuccess = () => {
        setSuccess(false);
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }


    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inner}>
                    {error && <Alert severity="error">{t(error)}</Alert>}

                    

                    <Typography variant="caption" component="div">* {t('REQUIRED_FIELDS')}</Typography>

                    <div className={classes.form}>
                        <InputField name='first_name' validation={validation.first_name} value={form.first_name} handleChange={handleInputChange} hoverLabel={true} />
                        <InputField name='last_name' validation={validation.last_name} value={form.last_name} handleChange={handleInputChange} hoverLabel={true} />
                        <InputField name='address1' validation={validation.address1} value={form.address1} handleChange={handleInputChange} hoverLabel={true}/>
                        <InputField name='address2' validation={validation.address2} value={form.address2} handleChange={handleInputChange} hoverLabel={true} />
                        <InputField name='address3' validation={validation.address3} value={form.address3} handleChange={handleInputChange} hoverLabel={true} />
                        <InputField name='locality' validation={validation.locality} value={form.locality} handleChange={handleInputChange} hoverLabel={true} />
                        <InputField name='province' validation={validation.province} value={form.province} handleChange={handleInputChange} hoverLabel={true} />
                        <InputField name='postal_code' validation={validation.postal_code} value={form.postal_code} handleChange={handleInputChange} hoverLabel={true} />
                        <InputField name='country_code' validation={validation.country_code} value={form.country_code} handleChange={handleInputChange} hoverLabel={true} type="select" items={ISOCountryCodes()} />
                        <InputField name='email' validation={validation.email} value={form.email} handleChange={handleInputChange} hoverLabel={true} />
                        <InputField name='phone' validation={validation.phone} value={form.phone} handleChange={handleInputChange} hoverLabel={true} />

                        <ProgressButton waiting={waiting} onClick={handleSubmit} variant='contained' disabled={!isValid}>{t('UPDATE')}</ProgressButton>
                    </div>
                </div>
            </div>

            <ElevateOverNotify show={success} side="right" handleClose={handleCloseSuccess} button={t('OK')} >
                <div className="flex-col align-center">
                    <img src={images.Icons_Tick_icon} style={{width: 140, height: 'auto'}} alt={t('YOUR_DETAILS_HAVE_BEEN_UPDATED')} />
                    <Typography variant="h5">{t('SUCCESS')}</Typography>
                    <Typography>{t('YOUR_DETAILS_HAVE_BEEN_UPDATED')}</Typography>
                </div>
            </ElevateOverNotify>
        </React.Fragment>
    )


}

export default ProfileEdit;