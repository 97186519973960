import React, {useContext, useState} from 'react';
import {makeStyles, Typography, useTheme, List, ListItem, ListItemText, ListItemAvatar, Avatar, IconButton} from '@material-ui/core';
import globalContext from '../context/globalContext';
import { useTranslation } from 'react-i18next';
import images from '../assets/imgs';
import StackSlidePanel from '../components/stackSlidePanel';
import ProfileEdit from '../content/profileEdit';
import ProfileChangePassword from '../content/profileChangePassword';
import ProfileTransactionHistory from '../content/profileTransactionHistory';
import ProfileBoatEdit from '../content/profileBoatEdit';
import ProfileNotifications from '../content/profileNotifications';
import ProfileBalance from '../content/profileBalance';
import ProfileReturnBalance from '../content/profileReturnBalance';
import ProfileAutoTopup from '../content/profileAutoTopup';
// import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
// import ProfileLinkAccount from '../content/profileLinkAccount';
// import ProfileLinkAccounts from '../content/profileLinkAccounts';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    minHeight: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  inner: {
    padding: 20
  },
  icon: {
    height: 26,
    width: 26,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main
  },
  smallIcon: {
    height: 20,
    width: 20
  },
  footerMenuItem: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    paddingLeft: 0
  },
}))

const Profile = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);
  const {t} = useTranslation();

  const [showNotifications, setShowNotifications] = useState(false);
  const [showEditDetails, setShowEditDetails] = useState(false);
  const [showEditBoat, setShowEditBoat] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showReturnBalance, setShowReturnBalance] = useState(false);
  const [showAutoTopup, setShowAutoTopup] = useState(false);
  const [showLinkAccount, setShowLinkAccount] = useState(false);
  const [showLinkedAccounts, setShowLinkedAccounts] = useState(false);

  const makeAddress = () => {
    let address = '';
    if(context.user.profile.user.address1 !== null && context.user.profile.user.address1 !== '') address += context.user.profile.user.address1 + ', '
    if(context.user.profile.user.address2 !== null && context.user.profile.user.address2 !== '') address += context.user.profile.user.address2 + ', '
    if(context.user.profile.user.address3 !== null && context.user.profile.user.address3 !== '') address += context.user.profile.user.address3 + ', '
    if(context.user.profile.user.locality !== null && context.user.profile.user.locality !== '') address += context.user.profile.user.locality + ', '
    if(context.user.profile.user.province !== null && context.user.profile.user.province !== '') address += context.user.profile.user.province + ', '
    if(context.user.profile.user.postal_code !== null && context.user.profile.user.postal_code !== '') address += context.user.profile.user.postal_code + ', '
    return address.length > 2 ? address.slice(0, -2) : address;
  }

  return (
    <React.Fragment>

    <div className={classes.container}>

      <div className={classes.inner}>

        <ProfileBalance />

        <div className='flex-col'>

          <div className='flex-row align-center'>
            <div>
                <Typography variant="subtitle2" color="primary">{t('YOUR_BOAT')}</Typography>
                <Typography variant="body2">{context.boat.data !== null ? <React.Fragment>{context.boat.data.boat_name} ({context.boat.data.boat_make} {context.boat.data.boat_model})</React.Fragment> : t('NO_BOAT_ADDED')}</Typography>
            </div>
            <div>
              <IconButton onClick={() => setShowEditBoat(true)}><Avatar src={images.Icons_Edit_Icon} alt="Edit" className={classes.icon} /></IconButton>
            </div>
          </div>

          <div className='flex-row align-center'>
            <div>
                <Typography variant="subtitle2" color="primary">{t('ABOUT_YOU')}</Typography>
            </div>
            <div>
              <IconButton onClick={() => setShowEditDetails(true)}><Avatar src={images.Icons_Edit_Icon} alt="Edit" className={classes.icon} /></IconButton>
            </div>
          </div>

          <div>
            <Typography variant="subtitle2">{t('NAME')}</Typography>
            <Typography variant="body2">{context.user.profile.user.first_name} {context.user.profile.user.last_name}</Typography>
          </div>

          <div>
            <Typography variant="subtitle2">{t('PHONE')}</Typography>
            <Typography variant="body2">{context.user.profile.user.phone || t('NO_PHONE_ADDED')}</Typography>
          </div>

          <div>
            <Typography variant="subtitle2">{t('EMAIL')}</Typography>
            <Typography variant="body2">{context.user.profile.user.email}</Typography>
          </div>

          <div>
            <Typography variant="subtitle2">{t('ADDRESS')}</Typography>
            <Typography variant="body2">{makeAddress() || t('NO_ADDRESS_ADDED')}</Typography>
          </div>

          
          {/* {context.links.data.length > 0 && 
            <div className='flex-row align-center'>
              <div>
                  <Typography variant="subtitle2" color="primary">{t('YOUR_LINKED_ACCOUNTS')}</Typography>
                  <Typography variant="body2">{context.links.data.length === 1 ? `1 ${t('LINKED_ACCOUNT')}` : `${context.links.data.length} ${t('LINKED_ACCOUNTS')}`}</Typography>
              </div>
              <div>
                <IconButton onClick={() => setShowLinkedAccounts(true)}><Avatar src={images.Icons_Edit_Icon} alt="Edit" className={classes.icon} /></IconButton>
              </div>
            </div>
          } */}

        </div>

        <div className='flex-col'>

          <List style={{paddingTop: 20}}>
            <ListItem button className={classes.footerMenuItem} onClick={() => setShowNotifications(true)}>
              <ListItemAvatar><Avatar src={images.Icons_Notifications_Icon} alt="Notifications" className={classes.icon} /></ListItemAvatar>
              <ListItemText primary={t('NOTIFICATIONS')} />
            </ListItem>
            <ListItem button className={classes.footerMenuItem} onClick={() => setShowTransactions(true)}>
              <ListItemAvatar><Avatar src={images.Icons_Transaction_Icon} alt="Transaction History" className={classes.icon} /></ListItemAvatar>
              <ListItemText primary={t('TRANSACTION_HISTORY')} />
            </ListItem>
            {/* <ListItem button className={classes.footerMenuItem} onClick={() => setShowLinkAccount(true)}>
              <ListItemAvatar><Avatar alt="Link Marina Account" className={classes.icon}><AllInclusiveIcon /></Avatar></ListItemAvatar>
              <ListItemText primary={t('LINK_MARINA_ACCOUNT')} />
            </ListItem> */}
            <ListItem button className={classes.footerMenuItem} onClick={() => setShowChangePassword(true)}>
              <ListItemAvatar><Avatar src={images.Icons_Password_Icon} alt="Change Password" className={classes.icon} /></ListItemAvatar>
              <ListItemText primary={t('CHANGE_PASSWORD')} />
            </ListItem>
            <ListItem button className={classes.footerMenuItem} onClick={() => setShowReturnBalance(true)}>
              <ListItemAvatar><Avatar src={images.Icons_Withdraw_Icon} alt="Return Balance" className={classes.icon} /></ListItemAvatar>
              <ListItemText primary={t('RETURN_BALANCE')} />
            </ListItem>
            <ListItem button className={classes.footerMenuItem} onClick={() => setShowAutoTopup(true)}>
              <ListItemAvatar><Avatar src={images.Icons_Auto_Wallet_Topup} alt="Auto Topup" className={classes.icon} /></ListItemAvatar>
              <ListItemText primary={t('AUTO_TOPUP')} />
            </ListItem>
            <ListItem button className={classes.footerMenuItem} onClick={context.logout}>
              <ListItemAvatar><Avatar src={images.Icons_Log_Out_Icon} alt="Sign out" className={classes.icon} /></ListItemAvatar>
              <ListItemText primary={t('SIGN_OUT')} />
            </ListItem>
          </List>
          
        </div>

      </div>

    </div>

    <StackSlidePanel side="right" title={t('NOTIFICATIONS')} show={showNotifications} handleClose={() => setShowNotifications(false)}><ProfileNotifications /></StackSlidePanel>
    <StackSlidePanel side="right" title={t('UPDATE_YOUR_DETAILS')} show={showEditDetails} handleClose={() => setShowEditDetails(false)}><ProfileEdit handleClose={() => setShowEditDetails(false)} /></StackSlidePanel>
    <StackSlidePanel side="right" title={t('BOAT_DETAILS')} show={showEditBoat} handleClose={() => setShowEditBoat(false)}><ProfileBoatEdit handleClose={() => setShowEditBoat(false)} /></StackSlidePanel>
    <StackSlidePanel side="right" title={t('TRANSACTION_HISTORY')} show={showTransactions} handleClose={() => setShowTransactions(false)}><ProfileTransactionHistory /></StackSlidePanel>
    <StackSlidePanel side="right" title={t('CHANGE_PASSWORD')} show={showChangePassword} handleClose={() => setShowChangePassword(false)}><ProfileChangePassword handleClose={() => setShowChangePassword(false)} /></StackSlidePanel>
    <StackSlidePanel side="right" title={t('RETURN_BALANCE')} show={showReturnBalance} handleClose={() => setShowReturnBalance(false)}><ProfileReturnBalance handleClose={() => setShowReturnBalance(false)} /></StackSlidePanel>
    <StackSlidePanel side="right" title={t('AUTO_TOPUP')} show={showAutoTopup} handleClose={() => setShowAutoTopup(false)}><ProfileAutoTopup handleClose={() => setShowAutoTopup(false)} /></StackSlidePanel>
    {/* <StackSlidePanel side="right" title={t('LINK_MARINA_ACCOUNT')} show={showLinkAccount} handleClose={() => setShowLinkAccount(false)}><ProfileLinkAccount handleClose={() => setShowLinkAccount(false)} /></StackSlidePanel> */}
    {/* <StackSlidePanel side="right" title={t('LINKED_ACCOUNTS')} show={showLinkedAccounts} handleClose={() => setShowLinkedAccounts(false)}><ProfileLinkAccounts handleClose={() => setShowLinkedAccounts(false)} /></StackSlidePanel> */}
    

    </React.Fragment>
  );
}

export default Profile;
