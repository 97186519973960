import { Link, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import images from '../assets/imgs';
import globalContext from '../context/globalContext';

const SupportHelp = props => {

    const context = useContext(globalContext);
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <div className="flex-col align-center p-b">
                <img src={images.Icons_Help_Blue_Icon} alt="Help" style={{width: 120}}/>
                <Typography variant="h6">{t('HELP_AND_SUPPORT')}</Typography>
            </div>

            <div className="flex-col gap-0 p-b">
                <Typography variant="body2">{t('HELP_BODY1')}</Typography>
            </div>

            <div className="flex-col gap-0 p-b">
                <Typography variant="caption">{t('EMAIL')}:</Typography>
                <Typography variant="subtitle2"><Link target="_blank" href="mailto:support@berthvend.com" color="primary">support@berthvend.com</Link></Typography>
                <Typography variant="caption">{t('HOURS')}: {t('MON_FRI')}</Typography>
            </div>

            <div className="flex-col gap-0 p-b">
                <Typography variant="caption">{t('ROLEC_ADDRESS')}:</Typography>
            </div>

            <div className="flex-col gap-0 p-b">
                <Typography variant="caption">{t('APP_VERSION')}: v{context.version}</Typography>
                <Typography variant="caption"><Link target="_blank" href="https://www.rolecserv.com/rolec-smart-solutions/app-end-user-licence-agreement---berthvend" color="primary">Terms &amp; Conditions</Link> | <Link target="_blank" href="https://www.rolecserv.com/rolec-smart-solutions/app-privacy-policy---berthvend" color="primary">Privacy Policy</Link></Typography>
            </div>
        </React.Fragment>
    )

}
export default SupportHelp;