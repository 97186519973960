import React, {useState, useEffect, useContext} from 'react';
import { useTheme, Typography, makeStyles, Table, TableBody, TableRow, TableCell, IconButton, Button, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import images from '../assets/imgs';
import API from '../global/api';
import { SQLtoUTCDate } from '../global/dateTimeHelpers';
import { localeCurrency } from '../global/helpers';
import Skeleton from '@material-ui/lab/Skeleton';
import EmailIcon from '@material-ui/icons/Email';
import SlideOverDialog from '../components/slideOverDialog';
import ServiceTransactionSendReceipt from '../content/serviceTransactionSendReceipt';
import DatePickerToolbar from '../components/datePickerToolbar';
import Alert from '@material-ui/lab/Alert';
import globalContext from '../context/globalContext';

const useStyles = makeStyles(theme => ({
  heading: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 25
  },
  title: {
    marginBottom: 15,
    fontWeight: 400
  },
  historyWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20,
  },
  noHistoryWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noHistory: {
    width: '100%',
    maxWidth: 260,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noHistoryImage: {
    width: '100%',
    height: 'auto',
    maxWidth: 80
  },
  noHistoryText: {
    color: theme.palette.grey[400]
  },
  skeleton: {
      height: 14,
      borderRadius: 12
  },
  dataKey: {
    backgroundColor: theme.palette.grey[100],
    padding: '8px 12px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 2,
    fontSize: 12,
    fontWeight: 600
  },
  download: {
      minWidth: 36
  }
}))

const History = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const {t} = useTranslation();

  const [error, setError] = useState(null);
  const [waiting, setWaiting] = useState(false);

  const [dates, setDates] = useState({
      from: new Date(new Date().setDate(new Date().getDate()-30)),
      to: new Date()
  })

  const [data, setData] = useState([]);
  const [dateKeys, setDateKeys] = useState([])

  const [sendReceipt, setSendReceipt] = useState(null);

  const [downloading, setDownloading] = useState(false);


  const loadServices = async () => {
      setWaiting(true);
      setError(null);

      try {

          const fetchServices = await API.req('/services/history', 'GET', dates);
          if(fetchServices.success)
              setData(fetchServices.result);
          else
              throw fetchServices.msg;

          
      } catch (error) {
          setError(error);
      } finally {
          setWaiting(false);
      }
  }

  useEffect(() => {
      loadServices()
  }, [])

  useEffect(() => {
    createDateKeys()
  }, [data])


  const createDateKeys = () => {

    let newKeys = [];
    data.map(d => {

      let dateKey = SQLtoUTCDate(d.start_time, false);
      if(newKeys.indexOf(dateKey) == -1)
        newKeys.push(dateKey);

    })
    setDateKeys(newKeys);

  }


  const dataByKey = (key) => {
    return data.filter(d => SQLtoUTCDate(d.start_time, false) === key)
  }


  const getMeterDisplay = service => {
      switch(parseInt(service.id_unit)){
          case 1:
          case 2:
              return (parseFloat(service.meter)/1000).toFixed(2).toString()
          default:
              return parseFloat(service.meter).toFixed(2).toString()
      }
  }


  const downloadServices = async () => {
      setError(null);
      setDownloading(true);

      try {

          const fetchServices = await API.fileDownload('/services/download', 'GET', dates);
          // The native wrapper handles downloads
          if(process.env.REACT_APP_NATIVE != 'true'){
            const url = window.URL.createObjectURL(new Blob([fetchServices.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `BerthVend_${new Date().getTime()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }

      } catch (error) {

          console.log(error);
          setError(typeof error === 'string' ? t(error) : t('ERR'));
      } finally {
        setDownloading(false);
      }

  }

  return (

    <React.Fragment>

      <div className={classes.heading}>
        <Typography variant="h5" color="primary" className={classes.title}>{t('SERVICE_HISTORY')}</Typography>
      </div>

      <div className={classes.historyWrapper}>

        {error && <Alert severity="error">{error}</Alert>}

        <DatePickerToolbar onChange={loadServices} dates={dates} setDates={setDates}>
          <Button variant="contained" size="small" onClick={downloadServices} classes={{root: classes.download}} disabled={downloading}>
            {downloading && <CircularProgress size={16} color="inherit" />}
            {!downloading && <img src={images.Icons_Download_Icon_White} style={{height: 16}} />}
          </Button>
        </DatePickerToolbar>

        {waiting && 
            <Table size="small">
                <TableBody>
                    {[1,2,3,4,5].map((row, idx) => (
                        <TableRow key={idx}>
                            <TableCell><Skeleton variant="rect" className={classes.skeleton} style={{width: 14}} /></TableCell>
                            <TableCell><Skeleton variant="rect" className={classes.skeleton} style={{width: 70}} /></TableCell>
                            <TableCell><Skeleton variant="rect" className={classes.skeleton} style={{width: 90}} /></TableCell>
                            <TableCell><Skeleton variant="rect" className={classes.skeleton} style={{width: 60}} /></TableCell>
                            <TableCell><Skeleton variant="rect" className={classes.skeleton} style={{width: 15}} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        }

        {(!waiting && data.length === 0) && 
          <div className={classes.noHistoryWrapper}>
            <div className={classes.noHistory}>
              <img src={images.Icons_History_LightGrey_Icon} alt="No service history" className={classes.noHistoryImage} />
              <Typography className={classes.noHistoryText}>{t('NO_SERVICE_HISTORY')}</Typography>
            </div>
          </div>
        }


        {!waiting &&

            dateKeys.map((key, idx) => (
              
              <React.Fragment key={idx}>

                <div className={classes.dataKey}>{key}</div>

                <Table size="small">
                  <TableBody>

                      {dataByKey(key).map((row, idx) => (
                          <TableRow key={idx}>
                              <TableCell><img src={row.id_service_type === 2 ? images.Icons_Water_Icon : images.Icons_Electric_Icon} style={{height: 16, width: 'auto'}} alt="Utility type" /></TableCell>
                              <TableCell style={{flex: 1}}><Typography variant="caption">{row.identity}</Typography></TableCell>
                              <TableCell align="right"><Typography variant="caption">{getMeterDisplay(row)} {row.unit}</Typography></TableCell>
                              <TableCell align="right"><Typography variant="caption"><b>{localeCurrency(row.cost, row.currency)}</b></Typography></TableCell>
                              <TableCell align="center">
                                {row.id_app_user === context.user.profile.user.id_user && <IconButton onClick={() => setSendReceipt(row)} size="small" color="primary"><EmailIcon fontSize="small" /></IconButton>}
                                {row.id_app_user !== context.user.profile.user.id_user && <img src={images.Icons_Marina_Grey_Icon} style={{height: 22, width: 'auto'}} /> }
                              </TableCell>
                          </TableRow>
                      ))}

                  </TableBody>
                </Table>

              </React.Fragment>

            ))
        }

      </div>

      <SlideOverDialog show={sendReceipt !== null} handleClose={() => setSendReceipt(null)} title={t('SEND_RECEIPT')}><ServiceTransactionSendReceipt transaction={sendReceipt} handleClose={() => setSendReceipt(null)} /></SlideOverDialog>

    </React.Fragment>

  );
}

export default History;
