import React, {useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, createMuiTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import { ThemeProvider } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
}));

const pickerTheme = (theme) => {
    
    return createMuiTheme({
        ...theme,
        overrides: {
            MuiInputBase: {
                input: {
                    minWidth: 80,
                    maxWidth: 100
                }
            },
            MuiInput: {
                root: {
                    fontSize: '.9rem',
                    fontWeight: 600
                },
                underline: {
                    '&:before': {
                        borderBottom: 'none'
                    },
                    '&:after': {
                        borderBottom: 'none'
                    },
                    '&:hover:before': {
                        content: 'none'
                    }
                }
            },
            MuiFormControl: {
                marginNormal: {
                    marginTop: 0,
                    marginBottom: 0,
                },
            }
        },
    });

}

let transactionLoadTimout;

const DatePickerToolbar = props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const handleDateChange = (idx, date) => {
        const newDates = JSON.parse(JSON.stringify(props.dates));
        newDates[idx] = date;
        if(JSON.stringify(props.dates) !== JSON.stringify(newDates))
            props.setDates(newDates);
    };

    useEffect(() => {

        if(typeof transactionLoadTimout !== 'undefined')
            clearTimeout(transactionLoadTimout);

        if(typeof props.onChange === 'function')
            transactionLoadTimout = setTimeout(props.onChange, 500)

        return () => {
            if(typeof transactionLoadTimout !== 'undefined')
                clearTimeout(transactionLoadTimout);
        }

    }, [props.dates])


    return (
        <React.Fragment>
            <div className={classes.dateToolbar}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="flex-row align-center gap-10" style={{borderBottom: `1px solid ${theme.palette.grey[700]}`, marginBottom: 16, paddingBottom: 8}}>
                            <ThemeProvider theme={pickerTheme(theme)}>
                                <Typography variant="caption">From</Typography>
                                <DatePicker
                                    margin="normal"
                                    id="from"
                                    format="dd/MM/yyyy"
                                    value={props.dates.from}
                                    onChange={date => handleDateChange('from', date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                <Typography variant="caption">To</Typography>
                                <DatePicker
                                    margin="normal"
                                    id="to"
                                    format="dd/MM/yyyy"
                                    value={props.dates.to}
                                    onChange={date => handleDateChange('to', date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </ThemeProvider>
                            <div className="flex"></div>
                            {props.children}
                        </div>
                </MuiPickersUtilsProvider>
            </div>
        </React.Fragment>
    )
}
export default DatePickerToolbar;