import React, { useState, useEffect } from 'react';
import {useTheme, makeStyles, IconButton, Box, Button} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Images from '../assets/imgs';

const useStyles = makeStyles((theme, color) => ({
  containerIn: {
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    transition: 'all .3s ease',
  },
  containerLeftOut: {
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    transform: 'translateX(-100%)',
    visibility: 'hidden',
    transition: 'all .3s ease',
  },
  containerRightOut: {
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    transform: 'translateX(100%)',
    visibility: 'hidden',
    transition: 'all .3s ease',
  },
  panelLeftOut: {
    display: 'flex',
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: 1300,
    backgroundColor: theme.palette.primary.main,
    overflowY: 'auto',
    overflowX: 'hidden',
    transform: 'translateX(-100%)',
    visibility: 'hidden',
    left: 0,
    top: 0,
    transition: 'all .3s ease',
    flexDirection: 'column',
    alignItems: 'center',
  },
  panelLeftIn: {
    display: 'flex',
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: 1300,
    backgroundColor: theme.palette.primary.main,
    overflowY: 'auto',
    overflowX: 'hidden',
    transform: 'translateX(0)',
    left: 0,
    top: 0,
    transition: 'all .3s ease',
    flexDirection: 'column',
    alignItems: 'center',
  }, 
  panelRightOut: {
    display: 'flex',
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: 1300,
    backgroundColor: theme.palette.primary.main,
    overflowY: 'auto',
    overflowX: 'hidden',
    transform: 'translateX(100%)',
    visibility: 'hidden',
    right: 0,
    top: 0,
    transition: 'all .3s ease',
    flexDirection: 'column',
    alignItems: 'center',
  },
  panelRightIn: {
    display: 'flex',
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: 1300,
    backgroundColor: theme.palette.primary.main,
    overflowY: 'auto',
    overflowX: 'hidden',
    transform: 'translateX(0)',
    right: 0,
    top: 0,
    transition: 'all .3s ease',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inner: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '86%',
    maxWidth: 400,
    paddingTop: 40,
    color: '#ffffff',
    textAlign: 'center'
  },
  close: {
    position: 'absolute',
    top: 20,
    right: 20,
    color: '#ffffff'
  },
  logo: {
    height: 30,
    width: 'auto',
    position: 'absolute',
    top: 20,
    left: 20,
  }
}));


const SlideOverNotify = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const side = typeof props.side !== 'undefined' ? props.side : 'LEFT';

  const [ show, setShow ] = useState(false)
  const [ render, setRender ] = useState(false);

  useEffect(() => {
    setShow(props.show);
    setRender(props.show);
  }, [props.show])

  const handleAnimationEnd = () => {
    if(show === false){
      setRender(false);
      if(typeof props.handleClose === 'function')
        props.handleClose();
    }
  }

  const handleClose = () => {
    setShow(false);
  }

  return (
    <div className={show ? classes.containerIn : (side.toUpperCase() === 'RIGHT' ? classes.containerRightOut : classes.containerLeftOut)}>
      {render && <Box 
        className={show ? (side.toUpperCase() === 'RIGHT' ? classes.panelRightIn : classes.panelLeftIn) : (side.toUpperCase() === 'RIGHT' ? classes.panelRightOut : classes.panelLeftOut)} 
        boxShadow={3}
        onTransitionEnd={handleAnimationEnd}
      >
          <IconButton onClick={handleClose} className={classes.close} color="inherit"><CloseIcon /></IconButton>
          <img src={Images.logo_white} className={classes.logo} alt="BerthVend Notification" />
          <div className={classes.inner}>
            {props.children}
            {props.button && 
              <div style={{marginTop: 10}}>
                <Button variant="contained" color="default" onClick={handleClose}>{props.button}</Button>
              </div>
            }
          </div>
      </Box>}
    </div>
  );
}

export default SlideOverNotify;
