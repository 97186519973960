import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import user from "../reducers/userReducer";
import boat from "../reducers/boatReducer";
import wallet from "../reducers/walletReducer";
import notifications from "../reducers/notificationsReducer";
import services from "../reducers/servicesReducer";
import organisations from "../reducers/organisationsReducer";
import links from "../reducers/linksReducer";

export default createStore(
    combineReducers({
        user,
        boat,
        notifications,
        wallet,
        services,
        organisations,
        links
    }),
    {},
    applyMiddleware(thunk)
)