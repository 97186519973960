import React, {useEffect, useState} from 'react';
import './styles.css';
import {makeStyles, useTheme, Typography, Button, FormControlLabel, Link} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeValidation, validateForm, isValidForm } from '../../global/formHelper';
import { useTranslation } from 'react-i18next';

import InputField from '../../components/inputField';
import ProgressButton from '../../components/progressButton';
import BooleanSwitch from '../../components/booleanSwitch';

const useStyles = makeStyles(theme => ({
  caption: {
    marginTop: 10
  },
  form: {
    marginBottom: 10,
    marginTop: 20
  }
}))


const Register = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();

  const [form, setForm] = useState({
    email: '',
    password: '',
    confirm_password: '',
    first_name: '',
    last_name: '',
    accept_terms: false
  })

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'password',
      display_name: t('PASSWORD'),
      required: true,
      additional: [
        {
          type: 'password',
          error: t('PASSWORD_REQUIREMENTS')
        }
      ]
    },
    {
      name: 'confirm_password',
      display_name: t('CONFIRM_PASSWORD'),
      required: true,
      additional: [
        {
          type: 'match',
          field: 'password',
          error: t('YOUR_PASSWORDS_DO_NOT_MATCH')
        }
      ]
    },
    {
      name: 'first_name',
      display_name: t('FIRST_NAME'),
      required: true
    },
    {
      name: 'last_name',
      display_name: t('LAST_NAME'),
      required: true
    },
    {
      name: 'email',
      display_name: t('EMAIL'),
      required: true
    }
  ]));

  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])

  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }
  
  const handleSwitchChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.checked;
    setForm(newForm);
  }


  const handleSubmit = () => {
    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid)
      props.handleRegister(form);
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter')
        handleSubmit();
  }
  
  useEffect(() => {
    setWaiting((props.user.status === 'loading'));
  }, [props.user])


  return (
    <React.Fragment>
      <div>

        <Typography variant="h5" color="primary" align="center">{t('REGISTER_AN_ACCOUNT')}</Typography>

        {props.user.msg && <Alert severity='error' style={{marginTop: 10}}>{t(props.user.msg)}</Alert>}

        <Typography variant="caption" className={classes.caption} component="div">* {t('REQUIRED_FIELDS')}</Typography>

        <div className={classes.form}>
          <InputField hoverLabel name='email' validation={validation.email} value={form.email} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
          <InputField hoverLabel name='first_name' validation={validation.first_name} value={form.first_name} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
          <InputField hoverLabel name='last_name' validation={validation.last_name} value={form.last_name} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
          <InputField hoverLabel name='password' type='password' validation={validation.password} value={form.password} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
          <InputField hoverLabel name='confirm_password' type='password' validation={validation.confirm_password} value={form.confirm_password} handleChange={handleInputChange} handleKeyDown={handleKeyDown} />
          <FormControlLabel
            labelPlacement="end"
            label={(
              <React.Fragment>
                <Typography variant="caption">{t('I_ACCEPT_THE')} <Link href="https://www.rolecserv.com/rolec-smart-solutions/app-privacy-policy---berthvend" color="primary" target="_blank">{t('PRIVACY_POLICY')}</Link> {t('AND')} <Link href="https://www.rolecserv.com/rolec-smart-solutions/app-end-user-licence-agreement---berthvend" color="primary" target="_blank">{t('TERMS_AND_CONDITIONS')}</Link></Typography>
              </React.Fragment>
            )}
            control={(
              <BooleanSwitch checked={form.accept_terms} handleSwitchChange={handleSwitchChange} name='accept_terms'  />
            )}>
          </FormControlLabel>

          <div className="flex-row" style={{marginTop: 30}}>
            <Button onClick={props.handleBack}><b>{t('BACK_TO_SIGNIN')}</b></Button>
            <ProgressButton variant="contained" size="large" color="default" waiting={waiting} onClick={handleSubmit} gutterBottom={true}>{t('REGISTER')}</ProgressButton>
          </div>
        </div>

      </div>

    </React.Fragment>
  );
}

export default Register;
