import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, CircularProgress, IconButton, Avatar, Button, Slide} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import globalContext from '../context/globalContext';
import { localeCurrency } from '../global/helpers';
import images from '../assets/imgs';
import StackSlidePanel from '../components/stackSlidePanel';
import SlideOverDialog from '../components/slideOverDialog';
import ProfileTopUp from '../content/profileTopUp';
import ProfileSwitchCurrency from '../content/profileSwitchCurrency';
import LockIcon from '@material-ui/icons/Lock';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
    balance: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: 40
    },
    balance_title: {
      marginBottom: 15,
      fontWeight: 400
    },
    balance_subtitle: {
      marginBottom: 5,
      fontWeight: 400
    },
    balance_amount: {
      fontWeight: 600,
      marginBottom: 0,
      lineHeight: 1
    },
    balance_currency: {
      fontWeight: 400,
      marginBottom: 0,
      lineHeight: 1.8
    },
    smallIcon: {
      height: 20,
      width: 20
    },
}));

let direction = 'left';

const ProfileBalance = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { t } = useTranslation();
    const context = useContext(globalContext);
    
    const [panel, setPanel] = useState(null);

    const [tabs, setTabs] = useState(0);
    const [tab, setTab] = useState(0);


    useEffect(() => {

      if(context.wallet.status === 'OK' && context.wallet.data.noWallet)
      setPanel('currency');

    }, [context.wallet])

    useEffect(() => {

      if(context.links.data.length > 0)
        setTabs(context.links.data.length)
      else
        setTabs(0);

    }, [context.links])

    
  const handleSkipBalanceForward = () => {
    direction = 'left';

    if(parseInt(tabs) === parseInt(tab))
      setTab(0);
    else
      setTab(parseInt(tab)+1);
  }

  const handleSkipBalanceBack = () => {
    direction = 'right';

    if(parseInt(tab) === 0)
      setTab(tabs);
    else
      setTab(parseInt(tab)-1);
  }

    return (
      <React.Fragment>
        <div className={classes.balance}>
            <Typography variant="h5" color="primary" className={classes.balance_title}>{props.title || t('YOUR_ACCOUNT')}</Typography>
            
            <div className="flex-row align-center justify-center">

              {tabs > 0 && <IconButton onClick={handleSkipBalanceBack}><ArrowBackIosIcon /></IconButton>}

              <div className="flex-row justify-center flex" style={{position: 'relative', height: tab > 0 ? 120 : 180, transition: 'all ease .3s'}}>

                  <Slide direction={tab === 0 ? direction : (tab > 0 && direction === 'left') ? 'right' : 'left'} in={tab === 0} mountOnEnter unmountOnExit>
                    <div style={{position: 'absolute', top: 0, left: 0, width: '100%'}}>
                      <Typography variant="subtitle2" className={classes.balance_subtitle}>{t('YOUR_CURRENT_BALANCE_IS')}</Typography>
                      <Typography variant="h2" className={classes.balance_amount}>{context.wallet.status === 'LOADING' ? <CircularProgress size={24} />: localeCurrency(context.wallet.data.balance, context.wallet.data.currency)}</Typography>
                      {context.wallet.status !== 'LOADING' && <Typography variant="subtitle2" className={classes.balance_currency}>({context.wallet.data.currency}) <IconButton onClick={() => setPanel('currency')} size="small"><Avatar src={images.Icons_Edit_Icon} alt="Switch currency" className={classes.smallIcon} /></IconButton></Typography>}
                      <div style={{paddingTop: 20}}>
                          <Button variant="contained" color="primary" onClick={() => setPanel('top_up')}>{t('TOP_UP')}</Button>
                      </div>
                    </div>
                  </Slide>

                  {context.links.data.map((l, idx) => (
                    <Slide direction={tab === idx + 1 ? direction : tab > idx + 1 ? 'right' : (tab === 0 && direction === 'left') ? 'right' : 'left'} in={tab === idx+1} mountOnEnter unmountOnExit>
                        <div style={{position: 'absolute', top: 0, left: 0, width: '100%'}}>
                          <Typography variant="subtitle2" className={classes.balance_subtitle}>{t('YOUR_CURRENT_MARINA_BALANCE_IS', {marina: l.organisation_name})}</Typography>
                          <Typography variant="h2" className={classes.balance_amount}>{context.links.status === 'LOADING' ? <CircularProgress size={24} />: localeCurrency(l.balance, l.currency)}</Typography>
                          {context.links.status !== 'LOADING' && <Typography variant="subtitle2" className={classes.balance_currency}>{l.customer_account_type}: ({l.currency})</Typography>}
                          {context.links.status !== 'LOADING' && <Typography variant="subtitle2">{l.first_name} {l.last_name}</Typography>}
                        </div>
                      </Slide>
                  ))}

              </div>

              {tabs > 0 && <IconButton onClick={handleSkipBalanceForward}><ArrowForwardIosIcon /></IconButton>}
            </div>
        </div>

        <StackSlidePanel side="right" title={
          <div className="flex-row-start">
            <LockIcon />
            {t('TOP_UP')}
          </div>
        } show={panel === 'top_up'} handleClose={() => setPanel(null)}><ProfileTopUp handleClose={() => setPanel(null)} /></StackSlidePanel>
        
        <SlideOverDialog show={panel === 'currency'} canClose={typeof context.wallet.data.noWallet === 'undefined'} handleClose={() => setPanel(null)} title={context.wallet.data.noWallet ? t('SELECT_YOUR_CURRENCY') : t('SWITCH_MY_CURRENCY')}><ProfileSwitchCurrency handleClose={() => setPanel(null)} /></SlideOverDialog>
      </React.Fragment>
    )
}
export default ProfileBalance;