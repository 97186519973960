import Api from '../global/api';

export function boatLoad () {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'BOAT_LOAD',
            status: 'LOADING',
            payload: null
        })

        Api.req(`/boat`, 'GET').then(
            res => {
                dispatch({
                    type: 'BOAT_LOAD',
                    status: 'OK',
                    payload: res.result
                })
            },
            err => {
                // Login error
                dispatch({
                    type: 'BOAT_LOAD',
                    status: 'error',
                    payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to load your boat'
                })
            }
        )        

    }
}

export function boatUpdate (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'BOAT_UPDATE',
            status: 'OK',
            payload: payload
        })
        
    }
}