const defaultFieldValidationObject = {
    dirty: false,
    error: false,
    required: false,
    msg: '',
    display_name: '',
    additional: []
};

export function makeValidation (fields) {
    let validation = {};
    fields.forEach(field => {
        let _validation = JSON.parse(JSON.stringify(defaultFieldValidationObject));
        _validation.required = field.required || false;
        _validation.display_name = field.display_name || field.name;
        _validation.additional = field.additional || [];
        validation[field.name] = _validation;
    })
    return validation;
}

export function validateForm (form, validationObj, dirtyAll = false) {
    
    // Whip up a copy so references don't get involved
    let nvo = JSON.parse(JSON.stringify(validationObj));

    // Loop through the validation objects
    Object.keys(validationObj).forEach(f => {

        // Set some defaults for each
        nvo[f].error = false;
        nvo[f].msg = '';
        nvo[f].dirty = dirtyAll || (typeof form[f] !== 'undefined' &&  form[f] !== null && form[f].length > 0) ? true : validationObj[f].dirty;
    
        // Lookup id the field is required and empty
        if(nvo[f].required && (typeof form[f] === 'undefined' || form[f] === null || form[f].length <= 0)){
            nvo[f].error = true;
            nvo[f].msg = `${nvo[f].display_name} is required.`
        }

        // If the field has additional validations to run
        if(typeof nvo[f].additional !== 'undefined' && nvo[f].dirty){

            // Loop through the additional validations
            for(let i=0;i<nvo[f].additional.length;i++){
                const v = nvo[f].additional[i];

                // Run the type of validation for what has been specified
                switch(v.type.toUpperCase()){
                    case 'MATCH':
                        if(typeof v.field !== 'undefined' && form[f] !== form[v.field]){
                            nvo[f].error = true;
                            nvo[f].msg = v.error
                        }
                        break;
                    case 'MIN':
                        if(typeof v.value !== 'undefined' && form[f].length < v.value){
                            nvo[f].error = true;
                            nvo[f].msg = v.error
                        }
                        break;
                    case 'PASSWORD':
                        if(!form[f].match(/^(?=.*[A-Za-z\W_])(?=.*\d)[\W_A-Za-z\d]{8,}$/)){
                            nvo[f].error = true;
                            nvo[f].msg = v.error
                        }
                        break;
                    case 'ABOVE':
                        if(isNaN(form[f]) || parseInt(form[f]) <= v.value){
                            nvo[f].error = true;
                            nvo[f].msg = v.error
                        }
                        break;
                    case 'BELOW':
                        if(isNaN(form[f]) || parseInt(form[f]) >= v.value){
                            nvo[f].error = true;
                            nvo[f].msg = v.error
                        }
                        break;
                    case 'PHONE':
                        if(!form[f].match(/^(\+[0-9]{6,})$/)){
                            nvo[f].error = true;
                            nvo[f].msg = v.error
                        }
                        break;
                    default:
                }
            }
        }
    });

    return nvo;
}

export function isValidForm (form, validationObj, dirty = true) {
    const validation = validateForm(form, validationObj, dirty);
    let result = {
        isValid: true,
        validation
    }
    Object.keys(validation).forEach(f => {
        if(validation[f].error === true)
            result.isValid = false;
    });
    return result;
}