import React, {useEffect, useState} from 'react';
import './styles.css';
import {makeStyles, useTheme, Typography} from '@material-ui/core';
import { makeValidation, validateForm, isValidForm } from '../../global/formHelper';

import Images from '../../assets/imgs/index';
import InputField from '../../components/inputField';
import ProgressButton from '../../components/progressButton';
import Alert from '@material-ui/lab/Alert';
import API from '../../global/api';

import background from "../../assets/imgs/Login_Screen.png";
import { useParams } from 'react-router';

const useStyles = makeStyles(theme => ({
  signinContainer: {
    width: '80%',
    maxWidth: 500,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '10%'
  },
  form: {
    marginBottom: 10
  },
  footer: {
    marginTop: 40
  },
  supportingLogo: {
    marginTop: '15%',
    width: '80%',
    maxWidth: 600,
    height: 'auto'
  },
  supportLogoImg: {
    width: '100%',
    height: 'auto'
  },
  welcome: {
    fontWeight: 300,
    fontSize: '1.6rem',
    textAlign: 'center',
    marginBottom: 30
  },
  title: {
    fontWeight: 600,
    fontSize: '1.6rem',
    textAlign: 'center',
    marginBottom: 0
  },
  getStartedWrapper: {
    backgroundColor: theme.palette.success.dark,
    padding: theme.spacing(2),
    marginBottom: 20,
    borderRadius: 3
  },
  getStarted: {
    fontWeight: 400,
    fontSize: '1rem',
    textAlign: 'center',
    marginBottom: 0,
    color: theme.palette.success.contrastText
  }
}))


const ResetPassword = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const {token} = useParams();

  const [form, setForm] = useState({
    token: token,
    new_password: '',
    confirm_password: ''
  })

  const [waiting, setWaiting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'new_password',
      display_name: 'New Password',
      required: true,
      additional: [
        {
          type: 'password',
          error: 'Password must be at least 8 characters and include letters and numbers'
        }
      ]
    },
    {
      name: 'confirm_password',
      display_name: 'Confirm Password',
      required: true,
      additional: [
        {
          type: 'match',
          field: 'new_password',
          error: 'Your passwords do not match'
        }
      ]
    }
  ]));

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])


  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = () => {
    setError(null);
    setSuccess(false);
    setWaiting(true);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){

      API.req(`/user/password/reset`, 'POST', form).then(
        res => {
          setWaiting(false);
          if(res.success === true){
            setSuccess(true);
            setForm({
              token: token,
              new_password: '',
              confirm_password: ''
            })
            setTimeout(() => {
              props.history.push('/');
            }, 3000)
          } else
            setError(res.msg);
        },
        err => {
          setWaiting(false);
          setError(err)
        }
      )

    }
  }


  return (
    <div className="container">

      {/* The image that displays on larger screens */}
      <div className="panel-left" style={{backgroundColor: theme.palette.primary.main,  backgroundImage: `url(${background})` }}>
        <div className={classes.supportingLogo}><img className={classes.supportingLogoImg} src={Images.logo_white_with_sub} alt="BerthVend" /></div>
      </div>

      {/* The actual content */}
      <div className="panel-right">


        <div className={classes.signinContainer}>
          
          <div style={{flex: 1}}></div>
          
          {/* Sign in form */}
          <div>

            <Typography variant="h4" className={classes.title} gutterBottom={true}>BerthVend Back-Office</Typography>
            <Typography variant="h4" className={classes.welcome} gutterBottom={false}>Reset Password</Typography>

            {error && <Alert severity='error'>{error}</Alert>}
            {success && <Alert severity='success'>Your password has been changed, you will be redirected to sign in shortly.</Alert>}

            <div className={classes.form}>
              <InputField type="password" name='new_password' size='large' validation={validation.new_password} value={form.new_password} handleChange={handleInputChange} />
              <InputField type="password" name='confirm_password' size='large' validation={validation.confirm_password} value={form.confirm_password} handleChange={handleInputChange} />
              <ProgressButton variant="contained" size="large" color="primary" fullWidth waiting={waiting} onClick={handleSubmit} gutterBottom={true}>Change Password</ProgressButton>
            </div>

          </div>

          <div style={{flex: 1}}></div>
        </div>

      </div>

    </div>
  );
}

export default ResetPassword;
