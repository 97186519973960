import Api from '../global/api';

export function linksLoad (quietly = false) {
    return async dispatch => {

        // Loading
        if(quietly === false)
            dispatch({
                type: 'LINKS_LOAD',
                status: 'LOADING',
                payload: null
            })


        Api.req(`/user/links`, 'GET').then(
            res => {

                dispatch({
                    type: 'LINKS_LOAD',
                    status: 'OK',
                    payload: res.result
                })

            },
            err => {
                // Login error
                dispatch({
                    type: 'LINKS_LOAD',
                    status: 'error',
                    payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to load your linked accounts'
                })
            }
        )

    }
}