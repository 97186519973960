import React, { useState, useEffect } from 'react';
import {useTheme, makeStyles, IconButton, Typography, Box} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  containerOut: {
    display: 'flex',
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: 1300,
    backgroundColor: '#fff',
    overflow: 'hidden',
    transform: 'translateX(100%)',
    right: 0,
    top: 0,
    inset: 0,
    transition: 'all .3s ease',
    flexDirection: 'column',
    alignItems: 'center',
    visability: 'hidden'
  },
  containerIn: {
    display: 'flex',
    position: 'fixed',
    height: '100%',
    width: '100%',
    zIndex: 1300,
    backgroundColor: '#fff',
    overflow: 'hidden',
    transform: 'translateX(0)',
    right: 0,
    top: 0,
    transition: 'all .3s ease',
    flexDirection: 'column',
    alignItems: 'center'
  },
  inner: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '86%',
    maxWidth: 600,
    paddingTop: 20,
    paddingBottom: 20,
    overflow: 'auto'
  },
  header: {
    width: '100%',
  },
  headerInner: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
  },
  title: {
    fontWeight: 400,
    color: theme.palette.primary.main
  }
}));


const StackSlidePanel = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const [ show, setShow ] = useState(props.show)
  const [ render, setRender ] = useState(false);

  useEffect(() => {
    setShow(props.show);
    setRender(props.show);
  }, [props.show])

  const handleAnimationEnd = () => {
    if(show === false){
      setRender(false);
      if(typeof props.handleClose === 'function')
        props.handleClose();
    }
  }

  const handleClose = () => {
    setShow(false);
  }

  return (
    <Box 
      className={show ? classes.containerIn : classes.containerOut} 
      onTransitionEnd={handleAnimationEnd}
    >
        <div className={classes.header}>
          <div className={classes.headerInner}>
            <IconButton onClick={handleClose} ><ArrowBackIcon /></IconButton>
            {props.title && <Typography variant='h5' className={classes.title}>{props.title}</Typography>}
          </div>
        </div>
        {render && 
        <div className={classes.inner}>
          {props.children}
        </div>
        }
    </Box>
  );
}

export default StackSlidePanel;
