import store from './store';
const {request} = require('axios'); 

class API {
    static ENDPOINT = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api/v1.0' : process.env.REACT_APP_ENDPOINT;

    /**
     * 
     * @param {*} uri The path to be added to the end of your endpoint e.g. /login
     * @param {*} method GET | POST | PUT | DELETE
     * @param {*} body The params to be passed either in the body of a POST or PUT or as the params of a GET
     * @param {*} headers Any custom headers to be sent
     * @returns Promise with the response from the server
     */
    static req = (uri = null, method = null, body = null, headers = {}) => {

        //
        //  Build the headers
        //
        let reqHeaders = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...headers
        };

        const state = store.getState();
        if(state.user.status === 'in' && state.user.profile.token !== null)
            reqHeaders['x-access-token'] = state.user.profile.token;

        /*
        * GAXIOS
        */
        return new Promise((resolve, reject) => {
            request({
                url: this.ENDPOINT + uri,
                method: method,
                headers: reqHeaders,
                data: method !== 'GET' ? body : null,
                params: method === 'GET' ? body : null,
                timeout: 15000,
                withCredentials: true
            })
            .then(res => {
                if(res.status === 200 && res.data.success === true)
                    resolve(res.data);
                else
                    reject(typeof res.data.msg !== 'undefined' ? res.data.msg : res);
            })
            .catch(err => {
                reject(typeof err.msg !== 'undefined' ? err.msg : err);
            })
        });
    }


    static fileUpload = (uri = null, body = null) => {


        //
        //  Build the headers
        //
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const state = store.getState();
        if(state.user.status === 'in' && state.user.profile.token !== null)
            headers['x-access-token'] = state.user.profile.token;

        
        let formData = new FormData();

        Object.keys(body).map(name => {
            formData.append(name, body[name]);
        })

        return new Promise((resolve, reject) => {
            request({
                url: this.ENDPOINT + uri,
                headers: headers,
                method: 'POST',
                data: formData,
                timeout: 10000,
                withCredentials: true
            })
            .then(res => {
                if(res.status === 200 && res.data.success === true)
                    resolve(res.data);
                else
                    reject(typeof res.data.msg !== 'undefined' ? res.data.msg : res);
            })
            .catch(err => {
                reject(typeof err.msg !== 'undefined' ? err.msg : err);
            })
        });

    }


    /**
     * 
     * @param {*} uri The path to be added to the end of your endpoint e.g. /login
     * @param {*} method GET | POST | PUT | DELETE
     * @param {*} body The params to be passed either in the body of a POST or PUT or as the params of a GET
     * @param {*} headers Any custom headers to be sent
     * @returns Promise with the response from the server
     */
     static fileDownload = (uri = null, method = null, body = null, headers = {}) => {

        //
        //  Build the headers
        //
        let reqHeaders = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...headers
        };

        const state = store.getState();
        if(state.user.status === 'in' && state.user.profile.token !== null)
            reqHeaders['x-access-token'] = state.user.profile.token;

        /*
        * AXIOS
        */
        return new Promise((resolve, reject) => {


            // We can't download nicely from inside a webview so let the native wrapper handle this
            if(process.env.REACT_APP_NATIVE == 'true' && typeof window.webViewBridge !== 'undefined') {
                window.webViewBridge.send('download', {
                    uri: uri, 
                    method: method, 
                    body: body, 
                    headers: reqHeaders
                }, true, false);
                resolve('NATIVE');
            }
            else {
                request({
                    url: this.ENDPOINT + uri,
                    method: method,
                    headers: reqHeaders,
                    data: method !== 'GET' ? body : null,
                    params: method === 'GET' ? body : null,
                    timeout: 15000,
                    withCredentials: true,
                    responseType: 'blob'
                })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(typeof err.msg !== 'undefined' ? err.msg : err);
                })
            }

        });
    }

    static addToLocalArray = async (storage_key, val) => {

        const local_array = localStorage.getItem(storage_key);
        let new_array = local_array !== null ? JSON.parse(local_array) : [];
        new_array.push(val);
        localStorage.setItem(storage_key, JSON.stringify(new_array));
        return {
            success: true,
            result: val
        };

    }

    static getLocal = async (storage_key) => {

        const local_array = localStorage.getItem(storage_key);
        if(local_array !== null && typeof JSON.parse(local_array) !== 'undefined')
            return JSON.parse(local_array)
        else
            return null;

    }

    static setLocal = async (storage_key, val) => {
        
        localStorage.setItem(storage_key, JSON.stringify(val));

    }

}
export default API