import Api from '../global/api';

export function organisationsLoad () {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'ORGANISATIONS_LOAD',
            status: 'LOADING',
            payload: null
        })


        Api.req(`/organisations`, 'GET').then(
            res => {

                dispatch({
                    type: 'ORGANISATIONS_LOAD',
                    status: 'OK',
                    payload: res.result
                })

            },
            err => {
                // Login error
                dispatch({
                    type: 'ORGANISATIONS_LOAD',
                    status: 'error',
                    payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to load your organisations'
                })
            }
        )

    }
}