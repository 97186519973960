import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import store from './global/store';

import './translation/i18n';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';

const palette = {
  type: 'light',
  primary: {
    main: '#00ADEE',
    contrastText: '#fff'
  },
  secondary: {
    main: '#FF0000',
    contrastText: '#fff'
  },
  success: {
    main: '#6CB33F',
    contrastText: '#fff'
  },
  error: {
    main: '#FF0000',
    contrastText: '#fff'
  },
  text: {
    primary: '#585858',
    contrastText: '#fff'
  },
  background: {
    default: '#ffffff'
  },
  vacant: {
    main: '#003C73',
    contrastText: '#fff'
  },
  occupied: {
    main: '#6cb33f',
    contrastText: '#fff'
  },
  reserved: {
    main: '#ecab00',
    contrastText: '#fff'
  },
  fault: {
    main: '#ff0000',
    contrastText: '#fff'
  }
};

const theme = createMuiTheme({
  palette: palette,
  typography: {
    fontSize: 14,
    h1: {
      fontSize: '3.2rem'
    },
    h2: {
      fontSize: '2.6rem'
    },
    h3: {
      fontSize: '2rem'
    },
    h4: {
      fontSize: '1.8rem'
    },
    h5: {
      fontSize: '1.4rem',
      fontWeight: 600
    },
    h6: {
      fontSize: '1.2rem'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderTopRightRadius: 12,
        borderTopLeftRadius: 12,
        borderBottomRightRadius: 12,
        borderBottomLeftRadius: 12
      },
      label: {
        textTransform: 'none'
      },
      containedSizeLarge: {
        padding: '12px 28px',
        fontSize: '.9rem'
      },
      contained: {
        backgroundColor: palette.text.primary,
        color: palette.text.contrastText,
        padding: '8px 28px',
        '&:hover': {
          backgroundColor: '#787878 !important',
          color: palette.text.contrastText,
        },
      }
    },
    MuiInputBase: {
      root: {
        borderBottomColor: 'transparent'
      }
    },
    MuiFilledInput: {
      root: {
        marginBottom: 6,
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        backgroundColor: '#f0f0f0',
        boxShadow: 'inset 0 0 2px #d2d2d2',
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: '#e2e2e2 !important',
          }
        },
      }
    },
    MuiSlider: {
      thumb: {
        color: palette.primary.contrastText,
        border: `2px solid ${palette.primary.main}`
      }
    },
    MuiTableCell: {
      head: {
        backgroundColor: palette.text.primary,
        color: palette.primary.contrastText
      },
      sizeSmall: {
        padding: '12px 4px 12px 2px'
      }
    },
    MuiTableSortLabel: {
      root: {
        color: palette.primary.contrastText,
        '&$hover': {
          color: palette.primary.contrastText,
        },
        '&$active': {
          color: palette.primary.contrastText,
          '&& $icon': {
            color: palette.primary.contrastText,
          },
        },
        '&& $icon': {
          color: palette.primary.contrastText,
        },
      }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0
      }
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#ffffff'
      }
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 12
      }
    }
  },
  props: {
    MuiFilledInput: {
      disableUnderline: true
    },
    MuiLink: {
      color: 'textPrimary'
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
