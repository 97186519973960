import React, { useState, useEffect } from 'react';
import {useTheme, makeStyles, Dialog, Typography, DialogContent, Slide, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
  },
  headerInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  close: {
    color: theme.palette.grey[400],
    paddingRight: 0
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const SlideOverDialog = props => {

  const size = props.size || 'sm';
  const canClose = typeof props.canClose !== 'undefined' ? props.canClose : true;

  const theme = useTheme();
  const classes = useStyles(theme);

  const [ show, setShow ] = useState(false)
  const [ render, setRender ] = useState(false);

  useEffect(() => {
    setShow(props.show);
    setRender(props.show);
  }, [props.show])

  const handleAnimationEnd = () => {
    if(show === false){
      setRender(false);
      if(typeof props.handleClose === 'function')
        props.handleClose();
    }
  }

  const handleClose = () => {
    setShow(false);
  }

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={canClose ? handleClose : () => {}}
      onTransitionEnd={handleAnimationEnd}
      fullWidth={false}
      maxWidth={size}
    >
      <DialogContent>
        <div className={classes.header}>
          <div className={classes.headerInner}>
            {props.title && <Typography variant={props.titleVariant || 'h6'} className={classes.title} color={props.color || 'inherit'}>{props.title}</Typography>}
            {canClose && <IconButton onClick={handleClose} className={classes.close} color="inherit"><CloseIcon /></IconButton>}
          </div>
        </div>

        {render && 
        <div className={classes.inner}>
          {props.children}
        </div>
        }
      </DialogContent>
    </Dialog>
  );
}

export default SlideOverDialog;
