import { Typography } from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import images from '../assets/imgs';
import InputField from '../components/inputField';
import ProgressButton from '../components/progressButton';
import Alert from '@material-ui/lab/Alert';
import { makeValidation, isValidForm} from'../global/formHelper';
import Api from '../global/api';
import ElevateOverNotify from '../components/elevateOverNotify'

const SupportFeedback = props => {

    const {t} = useTranslation();
    const [form, setForm] = useState({feedback: ''});
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [validation, setValidation] = useState(makeValidation([
        {
            name: 'feedback',
            display_name: t('YOUR_FEEDBACK'),
            required: true
        },
    ]));
    
    useEffect(() => {
        const valid = isValidForm(form, validation, false);
        setValidation(valid.validation);
        setIsValid(valid.isValid);
    }, [form])
    
    const handleInputChange = e => {
        let newForm = {...form};
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }
    
    const handleSubmit =  async () => {
        const valid = isValidForm(form, validation);
        setValidation(valid.validation);
        if(valid.isValid){
            setError(null);
            setWaiting(true);
            setSuccess(false);
            try {
                const result = await Api.req('/feedback', 'POST', form);
    
                if(result.success){
                    handleSuccess();
                } else
                    throw result.msg;
    
            } catch (error) {
                console.log('error');
                setError(typeof error === 'string' ? error : 'ERR');
            } finally { 
                console.log('finished');
                setWaiting(false);
            }
        }
    }

    const handleSuccess = async () => {
        setSuccess(true);
        let newForm = {...form};
        let newValidation = {...validation};
        newForm.feedback = '';
        newValidation.feedback.dirty = false;
        setForm(newForm);
        setValidation(newValidation);
    }

    const handleCloseSuccess = () => {
        setSuccess(false);

        if(typeof props.handleClose === 'function')
            props.handleClose();
    }

    return (
        <div className="flex-col align-center">

            <div className="flex-col align-center p-b">
                <img src={images.Icons_Email_Blue_Sent_Icon} alt={t('SEND_FEEDBACK')} style={{width: 120}}/>
                <Typography variant="h6">{t('SEND_FEEDBACK')}</Typography>
            </div>
            {error && <Alert severity="error">{error}</Alert>}
            <Typography variant="body2" className="p-b">{t('SEND_FEEDBACK_INTRO')}</Typography>
            <div style={{width: '100%'}}>
                <InputField name='feedback' validation={validation.feedback} value={form.feedback} handleChange={handleInputChange} hoverLabel={true} multiline rows="4" />
            </div>
            <ProgressButton waiting={waiting} disabled={!isValid} onClick={handleSubmit} variant="contained">{t('SEND')}</ProgressButton>


            <ElevateOverNotify show={success} side="right" handleClose={handleCloseSuccess} button={t('OK')} >
                <div className="flex-col align-center">
                    <img src={images.Icons_Email_Sent_Icon} style={{width: 140, height: 'auto'}} alt={t('YOUR_FEEDBACK_HAS_BEEN_SENT')} />
                    <Typography variant="h5">{t('SUCCESS')}</Typography>
                    <Typography>{t('YOUR_FEEDBACK_HAS_BEEN_SENT')}</Typography>
                </div>
            </ElevateOverNotify>
        </div>
    )
}
export default SupportFeedback;