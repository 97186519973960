import Icons_Add_Customer_Icon from '../../assets/imgs/Icons_Add Customer Icon.svg';
import Icons_Add_Icon from '../../assets/imgs/Icons_Add Icon.svg';
import Icons_Bin_Icon from '../../assets/imgs/Icons_Bin Icon.svg';
import Icons_Calendar_Icon from '../../assets/imgs/Icons_Calendar Icon.svg';
import Icons_Down_Arrow_Icon from '../../assets/imgs/Icons_Down Arrow Icon.svg';
import Icons_Download_Icon from '../../assets/imgs/Icons_Download Icon.svg';
import Icons_Download_Icon_White from '../../assets/imgs/Icons_Download Icon_White.svg';
import Icons_Edit_Icon from '../../assets/imgs/Icons_Edit Icon.svg';
import Icons_Electric_Icon from '../../assets/imgs/Icons_Electric Icon.svg';
import Icons_Email_Sent_Icon from '../../assets/imgs/Icons_Email Sent Icon.svg';
import Icons_Email_Blue_Sent_Icon from '../../assets/imgs/Icons_Email_Blue Sent Icon.svg';
import Icons_Help_Icon from '../../assets/imgs/Icons_Help Icon.svg';
import Icons_Help_Blue_Icon from '../../assets/imgs/Icons_Help_Blue Icon.svg';
import Icons_History_Icon from '../../assets/imgs/Icons_History Icon.svg';
import Icons_History_Grey_Icon from '../../assets/imgs/Icons_History_Grey Icon.svg';
import Icons_History_LightGrey_Icon from '../../assets/imgs/Icons_History_LightGrey Icon.svg';
import Icons_Home_Icon from '../../assets/imgs/Icons_Home Icon.svg';
import Icons_Home_Grey_Icon from '../../assets/imgs/Icons_Home_Grey Icon.svg';
import Icons_Log_Out_Icon from '../../assets/imgs/Icons_Log Out Icon.svg';
import Icons_Marina_Icon from '../../assets/imgs/Icons_Marina Icon.svg';
import Icons_Marina_LightGrey_Icon from '../../assets/imgs/Icons_Marina_LightGrey Icon.svg';
import Icons_Marina_Grey_Icon from '../../assets/imgs/Icons_Marina_Grey Icon.svg';
import Icons_Notifications_Icon from '../../assets/imgs/Icons_Notifications Icon.svg';
import Icons_Password_Icon from '../../assets/imgs/Icons_Password Icon.svg';
import Icons_Power_Icon from '../../assets/imgs/Icons_Power Icon.svg';
import Icons_Power_On_Icon from '../../assets/imgs/Icons_Power_On Icon.svg';
import Icons_Power_Off_Icon from '../../assets/imgs/Icons_Power_Off Icon.svg';
import Icons_Print_Icon from '../../assets/imgs/Icons_Print Icon.svg';
import Icons_Tick_icon from '../../assets/imgs/Icons_Tick icon.svg';
import Icons_Tick_Grey_icon from '../../assets/imgs/Icons_Tick_Grey icon.svg';
import Icons_Tick_Blue_icon from '../../assets/imgs/Icons_Tick_Blue icon.svg';
import Icons_Transaction_Icon from '../../assets/imgs/Icons_Transaction Icon.svg';
import Icons_User_Icon from '../../assets/imgs/Icons_User Icon.svg';
import Icons_User_Grey_Icon from '../../assets/imgs/Icons_User_Grey Icon.svg';
import Icons_Warning_Icon from '../../assets/imgs/Icons_Warning Icon.svg';
import Icons_Water_Icon from '../../assets/imgs/Icons_Water Icon.svg';
import logo from '../../assets/imgs/logo.svg';
import logo_with_sub from '../../assets/imgs/logo-with-sub.svg';
import logo_white from '../../assets/imgs/logo-white.svg';
import logo_white_with_sub from '../../assets/imgs/logo-white-with-sub.svg';
import Markers_Blue_Marker_Boat from '../../assets/imgs/Markers_Blue Marker - Boat.svg';
import Markers_Blue_Marker from '../../assets/imgs/Markers_Blue Marker.svg';
import Markers_Dark_Blue_Marker_Boat from '../../assets/imgs/Markers_Dark Blue Marker - Boat.svg';
import Markers_Dark_Blue_Marker from '../../assets/imgs/Markers_Dark Blue Marker.svg';
import Markers_Green_Marker_Boat from '../../assets/imgs/Markers_Green Marker - Boat.svg';
import Markers_Green_Marker from '../../assets/imgs/Markers_Green Marker.svg';
import Markers_Grey_Marker_Boat from '../../assets/imgs/Markers_Grey Marker - Boat.svg';
import Markers_Grey_Marker_Offline from '../../assets/imgs/Markers_Grey Marker - Offline.svg';
import Markers_Grey_Marker from '../../assets/imgs/Markers_Grey Marker.svg';
import Markers_Red_Marker_Alert from '../../assets/imgs/Markers_Red Marker - Alert.svg';
import Markers_Red_Marker_Boat from '../../assets/imgs/Markers_Red Marker - Boat.svg';
import Markers_Red_Marker from '../../assets/imgs/Markers_Red Marker.svg';
import Markers_Yellow_Marker_Boat from '../../assets/imgs/Markers_Yellow Marker - Boat.svg';
import Markers_Yellow_Marker from '../../assets/imgs/Markers_Yellow Marker.svg';
import Pedestal_Icons_Classic from '../../assets/imgs/Pedestal Icons_Classic.svg';
import Pedestal_Icons_Fin from '../../assets/imgs/Pedestal Icons_Fin.svg';
import Pedestal_Icons_MegaMaster from '../../assets/imgs/Pedestal Icons_MegaMaster.svg';
import Pedestal_Icons_Quantum from '../../assets/imgs/Pedestal Icons_Quantum.svg';
import Icons_Boat_Icon_Blue from '../../assets/imgs/Icons_Boat_Icon_Blue.svg';
import Icons_Boat_Icon_Grey from '../../assets/imgs/Icons_Boat_Icon_Grey.svg';
import Icons_Berth_Icon from '../../assets/imgs/Icons_Berth Icon.svg';
import Icons_Yacht_Icon from '../../assets/imgs/Icons_Yacht Icon.svg';
import Icons_RFID_Icon from '../../assets/imgs/Icons_RFID Icon.svg';
import Icons_Wallet_Grey_Icon from '../../assets/imgs/Icons_Wallet_Grey Icon.svg';
import Icons_Withdraw_Icon from '../../assets/imgs/Icons_Withdraw Icon.svg';
import Icons_Minus from '../../assets/imgs/Icons_Minus.svg';
import Icons_Auto_Wallet_Topup from '../../assets/imgs/Icons_Auto_Wallet_Topup.svg';
import Icons_Plus from '../../assets/imgs/Icons_Plus.svg';
import Rolec_Logo from '../../assets/imgs/Rolec_Logo.svg';

const images = {
    Icons_Add_Customer_Icon: Icons_Add_Customer_Icon,
    Icons_Add_Icon: Icons_Add_Icon,
    Icons_Bin_Icon: Icons_Bin_Icon,
    Icons_Calendar_Icon: Icons_Calendar_Icon,
    Icons_Down_Arrow_Icon: Icons_Down_Arrow_Icon,
    Icons_Download_Icon: Icons_Download_Icon,
    Icons_Download_Icon_White: Icons_Download_Icon_White,
    Icons_Edit_Icon: Icons_Edit_Icon,
    Icons_Electric_Icon: Icons_Electric_Icon,
    Icons_Email_Sent_Icon: Icons_Email_Sent_Icon,
    Icons_Email_Blue_Sent_Icon: Icons_Email_Blue_Sent_Icon,
    Icons_Help_Icon: Icons_Help_Icon,
    Icons_Help_Blue_Icon: Icons_Help_Blue_Icon,
    Icons_History_Icon: Icons_History_Icon,
    Icons_History_Grey_Icon: Icons_History_Grey_Icon,
    Icons_History_LightGrey_Icon: Icons_History_LightGrey_Icon,
    Icons_Home_Icon: Icons_Home_Icon,
    Icons_Home_Grey_Icon: Icons_Home_Grey_Icon,
    Icons_Log_Out_Icon: Icons_Log_Out_Icon,
    Icons_Marina_Icon: Icons_Marina_Icon,
    Icons_Marina_Grey_Icon: Icons_Marina_Grey_Icon,
    Icons_Marina_LightGrey_Icon: Icons_Marina_LightGrey_Icon,
    Icons_Notifications_Icon: Icons_Notifications_Icon,
    Icons_Password_Icon: Icons_Password_Icon,
    Icons_Power_Icon: Icons_Power_Icon,
    Icons_Power_On_Icon: Icons_Power_On_Icon,
    Icons_Power_Off_Icon: Icons_Power_Off_Icon,
    Icons_Print_Icon: Icons_Print_Icon,
    Icons_Tick_icon: Icons_Tick_icon,
    Icons_Tick_Grey_icon: Icons_Tick_Grey_icon,
    Icons_Tick_Blue_icon: Icons_Tick_Blue_icon,
    Icons_Transaction_Icon: Icons_Transaction_Icon,
    Icons_User_Icon: Icons_User_Icon,
    Icons_User_Grey_Icon: Icons_User_Grey_Icon,
    Icons_Warning_Icon: Icons_Warning_Icon,
    Icons_Water_Icon: Icons_Water_Icon,
    Icons_Minus,
    Icons_Plus,
    Icons_Auto_Wallet_Topup,
    logo: logo,
    logo_with_sub: logo_with_sub,
    logo_white: logo_white,
    logo_white_with_sub: logo_white_with_sub,
    Markers_Blue_Marker_Boat: Markers_Blue_Marker_Boat,
    Markers_Blue_Marker: Markers_Blue_Marker,
    Markers_Dark_Blue_Marker_Boat: Markers_Dark_Blue_Marker_Boat,
    Markers_Dark_Blue_Marker: Markers_Dark_Blue_Marker,
    Markers_Green_Marker_Boat: Markers_Green_Marker_Boat,
    Markers_Green_Marker: Markers_Green_Marker,
    Markers_Grey_Marker_Boat: Markers_Grey_Marker_Boat,
    Markers_Grey_Marker_Offline: Markers_Grey_Marker_Offline,
    Markers_Grey_Marker: Markers_Grey_Marker,
    Markers_Red_Marker_Alert: Markers_Red_Marker_Alert,
    Markers_Red_Marker_Boat: Markers_Red_Marker_Boat,
    Markers_Red_Marker: Markers_Red_Marker,
    Markers_Yellow_Marker_Boat: Markers_Yellow_Marker_Boat,
    Markers_Yellow_Marker: Markers_Yellow_Marker,
    Pedestal_Icons_Classic: Pedestal_Icons_Classic,
    Pedestal_Icons_Fin: Pedestal_Icons_Fin,
    Pedestal_Icons_MegaMaster: Pedestal_Icons_MegaMaster,
    Pedestal_Icons_Quantum: Pedestal_Icons_Quantum,
    Boat_Icons_Blue: Icons_Boat_Icon_Blue,
    Boat_Icons_Grey: Icons_Boat_Icon_Grey,
    Icons_Berth_Icon: Icons_Berth_Icon,
    Icons_Yacht_Icon, Icons_Yacht_Icon,
    Icons_RFID_Icon: Icons_RFID_Icon,
    Icons_Wallet_Grey_Icon: Icons_Wallet_Grey_Icon,
    Icons_Withdraw_Icon: Icons_Withdraw_Icon,
    Rolec_Logo: Rolec_Logo
}

export default images