import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import InputField from '../components/inputField';
import ProgressButton from '../components/progressButton';
import { makeValidation, isValidForm} from'../global/formHelper';
import Api from '../global/api';
import { useTranslation } from 'react-i18next';
import globalContext from '../context/globalContext';
import ElevateOverNotify from '../components/elevateOverNotify';
import images from '../assets/imgs';

const useStyles = makeStyles(theme => ({
    form: {
        marginTop: 20
    }
}));

const ProfileBoatEdit = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);
    const {t} = useTranslation();
    const [form, setForm] = useState({
        boat_type: '',
        boat_name: '',
        boat_make: '',
        boat_model: '',
        boat_length: ''
    });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [validation, setValidation] = useState(makeValidation([
        {
            name: 'boat_type',
            display_name: t('BOAT_TYPE'),
            required: true
        },
        {
            name: 'boat_name',
            display_name: t('BOAT_NAME'),
            required: true
        },
        {
            name: 'boat_make',
            display_name: t('BOAT_MAKE'),
            required: false
        },
        {
            name: 'boat_model',
            display_name: t('BOAT_MODEL'),
            required: false
        },
        {
            name: 'boat_length',
            display_name: t('BOAT_LENGTH'),
            required: false
        },
    ]));
    
    useEffect(() => {
        const valid = isValidForm(form, validation, false);
        setValidation(valid.validation);
        setIsValid(valid.isValid);
    }, [form])
    
    const handleInputChange = e => {
        let newForm = {...form};
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }
    
    const handleSubmit =  async () => {
        const valid = isValidForm(form, validation);
        setValidation(valid.validation);
        if(valid.isValid){
            setError(null);
            setWaiting(true);
            setSuccess(false);
            try {
                const result = await Api.req('/boat', 'PUT', form);
                console.log(result);
    
                if(result.success){
                    context.boatUpdate(result.result);
                    setSuccess(true);
                } else
                    throw result.msg;
    
            } catch (error) {
                console.log('error');
                setError(typeof error === 'string' ? error : 'ERR');
            } finally { 
                console.log('finished');
                setWaiting(false);
            }
        }
    }

    useEffect(() => {

        if(context.boat.status === 'LOADING')
            setWaiting(true);
        else
            setWaiting(false);

        if(context.boat.data !== null)
            setForm(context.boat.data);

    }, [context.boat])


    const handleCloseSuccess = () => {
        setSuccess(false);
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }
    

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inner}>
                    {error && <Alert severity='error'>{t(error)}</Alert>}


                    <Typography variant="caption" component="div">* {t('REQUIRED_FIELDS')}</Typography>
                    
                    <div className={classes.form}>
                        <InputField name='boat_type' validation={validation.boat_type} value={form.boat_type} handleChange={handleInputChange} hoverLabel={true} type="select" items={['Motor', 'Sail']} />
                        <InputField name='boat_name' validation={validation.boat_name} value={form.boat_name} handleChange={handleInputChange} hoverLabel={true} />
                        <InputField name='boat_make' validation={validation.boat_make} value={form.boat_make} handleChange={handleInputChange} hoverLabel={true} />
                        <InputField name='boat_model' validation={validation.boat_model} value={form.boat_model} handleChange={handleInputChange} hoverLabel={true} />
                        <InputField name='boat_length' validation={validation.boat_length} value={form.boat_length} handleChange={handleInputChange} hoverLabel={true} />
                    
                        <ProgressButton waiting={waiting} onClick={handleSubmit} variant='contained' disabled={!isValid}>{t('UPDATE')}</ProgressButton>
                    </div>
                </div>
            </div>

            <ElevateOverNotify show={success} side='right' handleClose={handleCloseSuccess} button={t('OK')} >
                <div className='flex-col align-center'>
                    <img src={images.Icons_Tick_icon} style={{width: 140, height: 'auto'}} alt={t('YOUR_BOAT_HAS_BEEN_UPDATED')} />
                    <Typography variant='h5'>{t('SUCCESS')}</Typography>
                    <Typography>{t('YOUR_BOAT_HAS_BEEN_UPDATED')}</Typography>
                </div>
            </ElevateOverNotify>
        </React.Fragment>
    )
}
export default ProfileBoatEdit;