import React, {useEffect, useState} from 'react';
import io from 'socket.io-client';

import { connect } from 'react-redux';
import { logout, userValidate, userUpdateProfile } from '../actions/userActions';
import { boatLoad, boatUpdate } from '../actions/boatActions';
import { walletLoad, walletUpdate } from '../actions/walletActions';
import { notificationsLoad, notificationsUpdate } from '../actions/notificationActions';
import { servicesLoad, serviceAdd, serviceUpdate, servicesLoadQuietly } from '../actions/serviceActions';
import { organisationsLoad} from '../actions/organisationActions';
import { linksLoad} from '../actions/linkActions';
import GlobalContext from './globalContext';
import MainToolbar from '../components/mainToolbar';
import StackSlidePanel from '../components/stackSlidePanel';

import { makeStyles, useTheme, SwipeableDrawer, List, ListItem, ListItemText, Typography, Tabs, Tab, AppBar, Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import images from '../assets/imgs';
import { useHistory, useLocation } from 'react-router-dom';
import SupportAbout from '../content/supportAbout';
import SupportHelp from '../content/supportHelp';
import SupportFeedback from '../content/supportFeedback';

const routes = ['/', '/profile', '/marinas', '/history'];

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    alignItems: 'center',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  inner: {
    width: '96%',
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingBottom: 60
  },
  drawer: {
    width: '100%',
    maxWidth: 260,
    backgroundColor: theme.palette.grey[700]
  },
  drawerInner: {
    padding: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.getContrastText(theme.palette.grey[700])
  },
  tabs: {
    top: 'auto',
    bottom: 0,
    borderTop: `1px solid ${theme.palette.grey[200]}`
  },
  tab: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  }
}))


//
//  Used for the silent refresh of the token
//
var silent_refresh;
var services_refresh;

const SOCKET_ENDPOINT = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/v1.0' : '/v1.0';
var SOCKET;

const Main = props => {

  //
  //  Some main structural pieces
  //
  const theme = useTheme();
  const classes = useStyles(theme);
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [ socketConnected, setSocketConnected ] = useState(false);
  const version = process.env.REACT_APP_VERSION || '0.0.0';


  //
  //  Basic functionality
  //
  const [showMenu, setShowMenu] = useState(false);
  const [panel, setPanel] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [tab, setTab] = useState(routes.indexOf(location.pathname) || 0);





  //
  //  Basic functionality methods
  //
  const handleShowMenu = () => {
    setShowMenu(true);
  }

  const handleHideMenu = () => {
    setShowMenu(false);
  }


  const handleChangeTab = (event, newValue) => {
    setTab(newValue);

    if(typeof history !== 'undefined')
      history.push(routes[newValue])
  };




  //
  //  On user events
  //

  useEffect(() => {
    props.boatLoad();    
    props.notificationsLoad();    
    props.walletLoad();       
    props.servicesLoad();  
    props.organisationsLoad(); 
    props.linksLoad(); 
  }, [])
  

  //
  //  Silent user refresh 
  //
  useEffect(() => {
    if(typeof silent_refresh != 'undefined')
      clearTimeout(silent_refresh);

    if(props.user.status === 'in')
      silent_refresh = setTimeout(props.userValidate, Math.floor((props.user.profile.expires*.9)*1000));
  }, [props.user])


  //
  // Services and wallet refresh
  //
  useEffect(() => {

    // Load the wallet when the services change
    props.walletLoad(true);  
    props.linksLoad(true);  

    if(typeof services_refresh != 'undefined')
      clearTimeout(services_refresh);

    if(props.user.status === 'in')
      services_refresh = setTimeout(props.servicesLoadQuietly, 15000);

  }, [props.services])




  const _socketListeners = (forceNew = false) => {

    if(forceNew && typeof SOCKET != 'undefined' && SOCKET !== null){
      SOCKET.close();
      SOCKET = null;
    }


    if(!SOCKET)
      SOCKET = io(SOCKET_ENDPOINT, {query: {token: props.user.profile.token}});

    if(typeof SOCKET !== 'undefined' && SOCKET !== null){

      if(typeof SOCKET._callbacks === 'undefined' || typeof SOCKET._callbacks['$connect'] === 'undefined')
        SOCKET.on('connect', () => {
          _joinRoom();
          setSocketConnected(true);
        })

      if(typeof SOCKET._callbacks === 'undefined' || typeof SOCKET._callbacks['$join'] === 'undefined')
        SOCKET.on('join', () => {
          _joinRoom();
        })
    
      if(typeof SOCKET._callbacks['$message'] == 'undefined')
        SOCKET.on('message', (message) => {
          //_handleIncommingMessage(message);
        })
  
  
      if(typeof SOCKET._callbacks === 'undefined' || typeof SOCKET._callbacks['$disconnect'] === 'undefined')
        SOCKET.on('disconnect', () => {
          setSocketConnected(false);
        })

    }

  }

  const _joinRoom = () => {
    if(typeof SOCKET !== 'undefined' && SOCKET !== null && props.profile.status === 'OK' && typeof props.profile.data.id_organisation !== 'undefined')
      SOCKET.emit('join', props.profile.data.id_organisation)
  }


  const getPanelTitle = () => {

    if(panel === null)
      return '';

    switch(panel.toUpperCase()){
      case 'ABOUT':
        return t('ABOUT_BERTHVEND');
      case 'HELP':
        return t('HELP');
      case 'FEEDBACK':
        return t('SEND_FEEDBACK');
    }
  }
  

  return (
    <GlobalContext.Provider value={{
      ...props,
      showMenu: handleShowMenu,
      hideMenu: handleHideMenu,
      version: version,
      socketConnected: socketConnected
    }}>
      {/* Routing compnents */}
      <div className={classes.container}>
        <MainToolbar handleShowMenu={handleShowMenu} />

        <SwipeableDrawer anchor='right' open={showMenu} onClose={handleHideMenu} onOpen={handleShowMenu} classes={{
          paper: classes.drawer
        }} style={{ zIndex: 1200 }}>
          <div className={classes.drawerInner}>
            <List className="flex">
              <ListItem button onClick={() => setPanel('about')}><ListItemText primary={t('ABOUT_BERTHVEND')} /></ListItem>
              <ListItem button onClick={() => setPanel('help')}><ListItemText primary={t('HELP')} /></ListItem>
              <ListItem button onClick={() => setShowFeedback(true)}><ListItemText primary={t('SEND_FEEDBACK')} /></ListItem>
              <ListItem button color="primary" onClick={props.logout}><ListItemText primary={t('SIGN_OUT')} /></ListItem>
            </List>
            <Typography variant="caption" align="right">v{version}</Typography>
          </div>
          <StackSlidePanel side="right" title={t('SEND_FEEDBACK')} show={showFeedback} handleClose={() => setShowFeedback(false)}><SupportFeedback handleClose={() => setShowFeedback(false)} /></StackSlidePanel>

          <StackSlidePanel side="right" title={getPanelTitle()} show={panel !== null} handleClose={() => setPanel(null)}>
            <React.Fragment>
              {panel !== null && 
                <React.Fragment>
                  {panel.toUpperCase() === 'ABOUT' && <SupportAbout />}
                  {panel.toUpperCase() === 'HELP' && <SupportHelp />}
                </React.Fragment>
              }
            </React.Fragment>
          </StackSlidePanel>
          
        </SwipeableDrawer>

        <div className={classes.inner}>
          {props.children}
        </div>

        <AppBar position="fixed" color="default" className={classes.tabs} elevation={0} style={{zIndex: 899}}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab icon={<Avatar alt="Home" src={tab === 0 ? images.Icons_Home_Icon : images.Icons_Home_Grey_Icon} className={classes.tab}/>} />
            <Tab icon={<Avatar alt="Account" src={tab === 1 ? images.Icons_User_Icon : images.Icons_User_Grey_Icon} className={classes.tab}/>} />
            <Tab icon={<Avatar alt="Marinas" src={tab === 2 ? images.Icons_Marina_Icon : images.Icons_Marina_Grey_Icon} className={classes.tab}/>} />
            <Tab icon={<Avatar alt="History" src={tab === 3 ? images.Icons_History_Icon : images.Icons_History_Grey_Icon} className={classes.tab}/>} />
          </Tabs>
        </AppBar>

      </div>
    </GlobalContext.Provider>
  );
}


const mapStateToProps = (state) => {
  return {
    user: state.user,
    boat: state.boat,
    notifications: state.notifications,
    wallet: state.wallet,
    services: state.services,
    organisations: state.organisations,
    links: state.links
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
    userValidate: () => {
      dispatch(userValidate());
    },
    userUpdateProfile: (payload) => {
      dispatch(userUpdateProfile(payload));
    },
    boatLoad: () => {
      dispatch(boatLoad());
    },
    boatUpdate: (payload) => {
      dispatch(boatUpdate(payload));
    },
    notificationsLoad: () => {
      dispatch(notificationsLoad());
    },
    notificationsUpdate: (payload) => {
      dispatch(notificationsUpdate(payload));
    },
    walletLoad: (quietly = false) => {
      dispatch(walletLoad(quietly));
    },
    walletUpdate: (payload) => {
      dispatch(walletUpdate(payload));
    },
    servicesLoad: () => {
      dispatch(servicesLoad());
    },
    serviceUpdate: (payload) => {
      dispatch(serviceUpdate(payload));
    },
    serviceAdd: (payload) => {
      dispatch(serviceAdd(payload));
    },
    servicesLoadQuietly: () => {
      dispatch(servicesLoadQuietly());
    },
    organisationsLoad: () => {
      dispatch(organisationsLoad());
    },
    linksLoad: (quietly = false) => {
      dispatch(linksLoad(quietly));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);