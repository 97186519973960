import React, {useContext, useState, useEffect} from 'react';
import { useTheme, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import images from '../assets/imgs';
import { useTranslation } from 'react-i18next';
import Api from '../global/api';
import { localeCurrency } from '../global/helpers';
import ProgressButton from '../components/progressButton';
import globalContext from '../context/globalContext';

const useStyles = makeStyles(theme => ({
    reserved: {
        color: theme.palette.reserved.main
    },
    available: {
        color: theme.palette.primary.main
    },
    fault: {
        color: theme.palette.error.main
    },
    offline: {
        color: theme.palette.error.main
    },
    occupied: {
        color: theme.palette.occupied.main
    }
}));

const ServiceAvailability = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);

    const {t} = useTranslation();

    const [waiting, setWaiting] = useState(false);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);
    const [utility, setUtility] = useState(null);
    const [isAvailable, setIsAvailable] = useState(false);


    const validate = () => {
        let valid = true;
        
        // Is the service actually available
        if(typeof utility === 'undefined' || utility === null || utility.service_status.toUpperCase() !== 'AVAILABLE')
            valid = false;

        if(parseFloat(context.wallet.data.balance) > 0 && parseFloat(context.wallet.data.balance) < 1) 
            setWarning('ERR_WALLET_LOW');

        // Are your funds in the same currency
        if(utility !== null && context.wallet.data !== null && utility.currency.toUpperCase() !== context.wallet.data.currency.toUpperCase()){
            console.log('currencies don\'t match')
            valid = false;
            setError('ERR_WALLET_CURRENCY_DOESNT_MATCH');
        }

        // Have you got funds
        if(context.wallet.data === null || parseFloat(context.wallet.data.balance) <= 0){
            valid = false;
            setError('ERR_WALLET_EMPTY');
        }

        setIsAvailable(valid);
    }


    useEffect(() => {

        const findUtility = async () => {

            setError(null);
            setUtility(null);
            setWarning(null);

            if(props.identity.length === 0)
                setError('ERR_NO_SERVICE_ID');
            else
                try {
                    setWaiting(true);

                    const result = await Api.req(`/utility/search`, 'GET', {identity: props.identity});
                    console.log(result);

                    if(result.success){
                        setUtility(result.result);
                    } else
                        throw result.msg;

                } catch (error) {
                    console.log('error');
                    setError(typeof error === 'string' ? error : 'ERR');
                } finally { 
                    console.log('finished');
                    setWaiting(false);
                }

        }
        findUtility();


    }, [props.identity])


    useEffect(() => {
        validate();
    }, [context.wallet, utility])


    const handleSubmit = async () => {

        try {
            setWaiting(true);
            setError(false);

            const result = await Api.req(`/service/start`, 'POST', {id_utility: utility.id_utility});
            console.log(result);

            if(result.success){
                
                context.servicesLoad();
                context.organisationsLoad();

                if(typeof props.handleSuccess === 'function')
                    props.handleSuccess();
                else if(typeof props.handleClose === 'function')
                    props.handleClose();
            } else
                throw result.msg;

        } catch (error) {
            console.log('error');
            setError(typeof error === 'string' ? error : 'ERR');
        } finally { 
            console.log('finished');
            setWaiting(false);
        }

    }

    return (
        <React.Fragment>
            <div className="flex-col" style={{paddingBottom: 15}}>

                {waiting && <div className="flex-col align-center p-t p-b"><CircularProgress size={24} /></div>}

                {(error && utility === null)  && <Alert severity="error">{t(error)}</Alert>}
                {(warning && utility === null) && <Alert severity="warning">{t(warning)}</Alert>}


                {(!waiting && utility !== null) &&

                    <React.Fragment>

                        <div className="flex-row p-b align-center">
                            <div className="flex-row-start gap-0 align-center">
                                <img src={utility.utility_type.toUpperCase() === 'WATER' ? images.Icons_Water_Icon : images.Icons_Electric_Icon} style={{height: 20, width: 'auto', marginRight: 5}} alt="Utility type" />
                                <Typography color="primary" variant="body2">{utility.utility_type.toUpperCase() === 'WATER' ? t('WATER') : t('ELECTRICITY')}</Typography>
                            </div>
                            <Typography variant="body2">{utility.identity}</Typography>
                        </div>

                        {error && <Alert severity="error">{t(error)}</Alert>}

                        <div className="flex-col p-b gap-0">
                            <Typography variant="subtitle2" gutterBottom={true}>{utility.organisation_name}</Typography>
                            <Typography variant="body2">{utility.organisation_address}</Typography>
                        </div>

                        <div className="flex-col gap-0">
                            <Typography variant="body2" gutterBottom={true}>{t('STATUS')}: <b className={classes[utility.service_status]}>{t(utility.service_status.toUpperCase())} {utility.breakerOk === false && t('ERR_BREAKER_TRIPPED')}</b></Typography>
                            <Typography variant="body2" gutterBottom={true}>{t('TYPE')}: <b>{utility.utility_description}</b></Typography>
                            <Typography variant="body2" gutterBottom={true}>{t('TARIFF')}: <b>{localeCurrency(utility.rate, utility.currency)} / {utility.unit}</b></Typography>
                        </div>

                        {warning && <Alert severity="warning">{t(warning)}</Alert>}

                        <div className="flex-col align-center">
                            <div>
                                <ProgressButton waiting={false} variant="contained" disabled={!isAvailable} onClick={handleSubmit}>{t('USE')}</ProgressButton>
                            </div>
                        </div>

                    </React.Fragment>

                }

            </div>
        </React.Fragment>
    )
}
export default ServiceAvailability;