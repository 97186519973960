import React from 'react';
import { useTheme, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ProfileNotification from './profileNotification';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
    title: {
        paddingBottom: 20
    },
    notification: {
        paddingBottom: 20
    }
}));

const FormComponent = props => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const {t} = useTranslation();

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inner}>

                    <Typography variant="subtitle2" align="center" className={classes.title}>{t('HOW_YOU_WISH_TO_RECEIVE_NOTIFICATIONS')}</Typography>


                    <div className={classes.notification}><ProfileNotification name="push" label={t('PUSH_NOTIFICATIONS')} /></div>

                    <div className={classes.notification}><ProfileNotification name="email" label={t('EMAIL')} /></div>

                    <div className={classes.notification}><ProfileNotification name="sms" label={t('SMS')} /></div>

                    <Typography variant="subtitle2" align="center" className={classes.title}>{t('WHICH_NOTIFICATIONS_YOU_WISH_TO_RECEIVE')}</Typography>

                    <div className={classes.notification}><ProfileNotification name="unplugged_socket" label={t('UNPLUGGED_SOCKET')} /></div>

                    <div className={classes.notification}><ProfileNotification name="tripped_power" label={t('TRIPPED_POWER')} /></div>

                    <div className={classes.notification}><ProfileNotification name="service_off" label={t('SERVICE_OFF')} /></div>

                    <div className={classes.notification}><ProfileNotification name="low_balance" label={t('LOW_BALANCE')} /></div>

                </div>
            </div>
        </React.Fragment>
    )
}
export default FormComponent;