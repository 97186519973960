import React, {useContext, useState} from 'react';
import { useTheme, Typography, makeStyles, Collapse } from '@material-ui/core';
import globalContext from '../context/globalContext';
import { useTranslation } from 'react-i18next';
import images from '../assets/imgs';
import ProgressButton from '../components/progressButton';
import API from '../global/api';
import Alert from '@material-ui/lab/Alert';
import BooleanSwitch from '../components/booleanSwitch';
import PriceTicker from '../components/priceTicker';

const useStyles = makeStyles(theme => ({
    container: {},
    inner: {},
    balance_subtitle: {
      marginBottom: 5,
      fontWeight: 400
    },
    balance_amount: {
      fontWeight: 600,
      marginBottom: 0,
      lineHeight: 1
    },
    balance_currency: {
      fontWeight: 400,
      marginBottom: 0,
      lineHeight: 1.8
    }
}));

const ProfileAutoTopup = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);
    const {t} = useTranslation();

    const [waiting, setWaiting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const [enabled, setEnabled] = useState(Boolean(context.wallet.data.autotopup_enabled));
    const [amount, setAmount] = useState(context.wallet.data.autotopup_amount);
    const [trigger, setTrigger] = useState(context.wallet.data.autotopup_trigger);

    const handleRefund = async () => {
        setWaiting(true);
        setSuccess(false);
        setError(null);

        try {
            
            const req = await API.req('/wallet/autotopup', 'PUT', {
                autotopup_enabled: enabled,
                autotopup_amount: amount,
                autotopup_trigger: trigger,
            });

            if(Boolean(req.success) === true){
                setSuccess(true);
                context.walletUpdate(req.result);
            } else
                setError(req.result.msg)

            

        } catch (error) {
            console.log(error);
            setError(error)
        } finally {
            setWaiting(false);
        }
    }


    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inner}>

                    <React.Fragment>

                        <div className="flex-col align-center p-b p-t">
                            <img src={images.Icons_Auto_Wallet_Topup} alt="Help" style={{width: 80}}/>
                            <Typography variant="body2" className={classes.balance_subtitle}>{t('AUTO_TOPUP_DESC')}</Typography>

                            {error && <Alert severity="error">{t(error)}</Alert>}
                        </div>

                        <div className="flex-col align-center gap-0">

                            <Collapse in={success}><Alert severity='success'>{t('AUTO_TOPUP_SUCCESS')}</Alert></Collapse>
                            <Collapse in={error}><Alert severity='error'>{t('AUTO_TOPUP_ERROR')}</Alert></Collapse>

                            <div className="flex-row align-center" style={{width: '100%'}}>
                                <Typography variant="body1" style={{flex: 1}}>{t('ENABLED')}</Typography>
                                <div className='flex'></div>
                                <BooleanSwitch checked={enabled} handleSwitchChange={() => setEnabled(!enabled)}  />
                            </div>

                            <Collapse in={enabled}>
                                <PriceTicker amount={trigger} currency={context.wallet.data.currency} label={t('AUTO_TOPUP_TRIGGER_TITLE')} onChange={setTrigger} />
                                <PriceTicker amount={amount} currency={context.wallet.data.currency} label={t('AUTO_TOPUP_AMOUNT_TITLE')} onChange={setAmount} />
                            </Collapse>

                        </div>

                        <div className="flex-col align-center p-b p-t">
                            <ProgressButton waiting={waiting} onClick={handleRefund} variant="contained">Save</ProgressButton>
                        </div>

                    </React.Fragment>

                </div>
            </div>
        </React.Fragment>
    )
}
export default ProfileAutoTopup;