import Api from '../global/api';

export function walletLoad (quietly = false) {
    return async dispatch => {

        // Loading
        if(quietly === false)
            dispatch({
                type: 'WALLET_LOAD',
                status: 'LOADING',
                payload: null
            })

        Api.req(`/wallet`, 'GET').then(
            res => {
                dispatch({
                    type: 'WALLET_LOAD',
                    status: 'OK',
                    payload: res.result !== null ? res.result : {balance: 0.00, currency: 'GBP', noWallet: true}
                })
            },
            err => {
                // Login error
                dispatch({
                    type: 'WALLET_LOAD',
                    status: 'error',
                    payload: typeof err.msg === 'string' ? err.msg : 'Sorry we were unable to load your wallet'
                })
            }
        )        

    }
}

export function walletUpdate (payload) {
    return async dispatch => {

        // Loading
        dispatch({
            type: 'WALLET_UPDATE',
            status: 'OK',
            payload: payload
        })
        
    }
}