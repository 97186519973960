import React, { useState, useEffect } from 'react';
import {useTheme, makeStyles, IconButton, Box, Button} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx'

const useStyles = makeStyles((theme, color) => ({
  container: {
    position: 'fixed',
    zIndex: 1300,
    inset: 0,
    transition: 'all .3s ease'
  },
  containerOut: {
    transform: 'translate(0%, 100%)',
    visibility: 'hidden',
    transition: 'all .3s ease',
  },
  panel: {
    display: 'flex',
    position: 'fixed',
    width: '80%',
    maxWidth: 320,
    zIndex: 999,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.primary.main,
    overflowY: 'auto',
    overflowX: 'hidden',
    transition: 'all .3s ease',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '12px',
  },
  panelOut : {
    top: 0,
    transform: 'translate(-50%, 100%)',
    visibility: 'hidden',
  },
  inner: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '86%',
    maxWidth: 400,
    padding: '40px 10px',
    color: '#ffffff',
    textAlign: 'center'
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#ffffff'
  },
  logo: {
    height: 30,
    width: 'auto',
    position: 'absolute',
    top: 20,
    left: 20,
  }
}));


const ElevateOverNotify = props => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const [ show, setShow ] = useState(false)
  const [ render, setRender ] = useState(false);

  useEffect(() => {
    setShow(props.show);
    setRender(props.show);
  }, [props.show])

  const handleAnimationEnd = () => {
    if(show === false){
      setRender(false);
      if(typeof props.handleClose === 'function')
        props.handleClose();
    }
  }

  const handleClose = () => {
    setShow(false);
  }

  return (
    <div className={clsx([classes.container, !show ? classes.containerOut : {}])}>
      {render && <Box 
        className={clsx([classes.panel, !show ? classes.panelOut : {}])}
        boxShadow={3}
        onTransitionEnd={handleAnimationEnd}
      >
          <IconButton onClick={handleClose} className={classes.close} color="inherit"><CloseIcon /></IconButton>
          <div className={classes.inner}>
            {props.children}
            {props.button && 
              <div style={{marginTop: 10}}>
                <Button variant="contained" color="default" onClick={handleClose}>{props.button}</Button>
              </div>
            }
          </div>
      </Box>}
    </div>
  );
}

export default ElevateOverNotify;
