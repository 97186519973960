import { makeStyles, useTheme, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../components/inputField';
import ProgressButton from '../components/progressButton';
import { makeValidation, isValidForm} from'../global/formHelper';
import Api from '../global/api';
import ElevateOverNotify from '../components/elevateOverNotify';
import images from '../assets/imgs';


const useStyles = makeStyles(theme => ({
    container: {

    }, 
    inner: {

    },
    form: {
        marginTop: 20
    }
}));

const ProfileChangePassword = props => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    const [form, setForm] = useState({
        password: '',
        confirm_password: ''
    });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const [validation, setValidation] = useState(makeValidation([
        {
        name: 'password',
        display_name: t('PASSWORD'),
        required: true,
        additional: [
          {
            type: 'password',
            error: t('PASSWORD_REQUIREMENTS')
          }
        ]
        },
        {
        name: 'confirm_password',
        display_name: t('CONFIRM_PASSWORD'),
        required: true,
        additional: [
          {
            type: 'match',
            field: 'password',
            error: t('YOUR_PASSWORDS_DO_NOT_MATCH')
          }
        ]
        }
    ]));

  
    useEffect(() => {
        const valid = isValidForm(form, validation, false);
        setValidation(valid.validation);
        setIsValid(valid.isValid);
    }, [form])


    const handleInputChange = e => {
        let newForm = {...form};
        newForm[e.target.name] = e.target.value;
        setForm(newForm);
    }

    const handleSubmit =  async () => {
        const valid = isValidForm(form, validation);
        setValidation(valid.validation);
        if(valid.isValid){

            setError(null);
            setWaiting(true);
            setSuccess(false);
            console.log('valid');
            try {
                const result = await Api.req('/user/password', 'PUT', form);
                console.log(result);

                if(result.success)
                    setSuccess(true);
                else
                    throw result.msg;

            } catch (error) {
                console.log('error');
                setError(typeof error === 'string' ? error : 'ERR');
            } finally { 
                console.log('finished');
                setWaiting(false);
            }

        }
    }


    const handleCloseSuccess = () => {
        setSuccess(false);
        if(typeof props.handleClose === 'function')
            props.handleClose();
    }


    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inner}>
                    {error && <Alert severity="error">{t(error)}</Alert>}

                    
                    <Typography variant="caption" component="div">* {t('REQUIRED_FIELDS')}</Typography>

                    <div className={classes.form}>
                        <InputField type='password' name='password' validation={validation.password} value={form.password} handleChange={handleInputChange} hoverLabel={true} />
                        <InputField type='password' name='confirm_password' validation={validation.confirm_password} value={form.confirm_password} handleChange={handleInputChange} hoverLabel={true} />

                        <ProgressButton waiting={waiting} onClick={handleSubmit} variant='contained' disabled={!isValid}>{t('UPDATE')}</ProgressButton>
                    </div>
                </div>
            </div>

            <ElevateOverNotify show={success} side="right" handleClose={handleCloseSuccess} button={t('OK')} >
                <div className="flex-col align-center">
                    <img src={images.Icons_Tick_icon} style={{width: 140, height: 'auto'}} alt={t('YOUR_PASSWORD_HAS_BEEN_CHANGED')} />
                    <Typography variant="h5">{t('SUCCESS')}</Typography>
                    <Typography>{t('YOUR_PASSWORD_HAS_BEEN_CHANGED')}</Typography>
                </div>
            </ElevateOverNotify>
        </React.Fragment>
    )


}

export default ProfileChangePassword;