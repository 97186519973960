import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import images from '../assets/imgs';

const SupportAbout = props => {

    const {t} = useTranslation();

    return (
        <div>
            <div className="flex-col align-center p-b">
                <img src={images.logo} alt={t('ABOUT_BERTHVEND')}  style={{height: 42, marginBottom: 20}}/>
            </div>
            <Typography variant="body2" className="p-b">{t('ABOUT_ONE')}</Typography>
            <Typography variant="body2" className="p-b">{t('ABOUT_TWO')}</Typography>
            <div className="flex-col align-center p-b">
                <img src={images.Rolec_Logo} alt={t('ABOUT_ROLEC')}  style={{height: 26, marginBottom: 20, marginTop: 36}}/>
            </div>
            <Typography variant="body2" className="p-b">{t('ABOUT_THREE')}</Typography>
            <Typography variant="body2" className="p-b">{t('ABOUT_FOUR')}</Typography>
        </div>
    )
}
export default SupportAbout;