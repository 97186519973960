import React, {useContext, useEffect, useState} from 'react';
import Api from '../global/api';
import BooleanSwitch from '../components/booleanSwitch';
import globalContext from '../context/globalContext';
import { CircularProgress, Typography } from '@material-ui/core';


const ProfileNotification = props => {

    const context = useContext(globalContext);

    const [form, setForm] = useState({
        setting_name: props.name,
        setting_value: false
    });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [waiting, setWaiting] = useState(false);
    
    const handleSubmit = async (e) => {
        setError(null);
        setWaiting(true);
        setSuccess(false);
        try {

            const result = await Api.req('/notification', 'PUT', {
                setting_name: props.name,
                setting_value: e.target.checked
            });
            console.log(result);

            if(result.success){
                context.notificationsUpdate(result.result)
                setSuccess(true);
            } else
                throw result.msg;

        } catch (error) {
            console.log('error');
            setError(typeof error === 'string' ? error : 'ERR');
        } finally { 
            console.log('finished');
            setWaiting(false);
        }
    }

    useEffect(() => {
        const newForm = JSON.parse(JSON.stringify(form));
        newForm.setting_value = context.notifications.data !== null ? Boolean(context.notifications.data[newForm.setting_name]) : false;
        setForm(newForm);
    }, [context.notifications])
    

    return (
        <React.Fragment>
            <div className="flex-row align-center">
                <Typography variant="body1" style={{flex: 1}}>{props.label}</Typography>
                {waiting && <CircularProgress size={16} />}
                <BooleanSwitch checked={form.setting_value} handleSwitchChange={handleSubmit} name={form.setting_name}  />
            </div>
        </React.Fragment>
    )
}
export default ProfileNotification;