import { makeStyles, useTheme, Table, TableBody, TableRow, TableCell, IconButton, Typography, Button, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import API from '../global/api';
import { SQLtoUTCDate } from '../global/dateTimeHelpers';
import { localeCurrency } from '../global/helpers';
import EmailIcon from '@material-ui/icons/Email';
import SlideOverDialog from '../components/slideOverDialog';
import ProfileTransactionResendReceipt from './profileTransactionResendReceipt';
import Skeleton from '@material-ui/lab/Skeleton';
import DatePickerToolbar from '../components/datePickerToolbar';
import images from '../assets/imgs';


const useStyles = makeStyles(theme => ({
    skeleton: {
        height: 14,
        borderRadius: 12
    },
    dataKey: {
        backgroundColor: theme.palette.grey[100],
        padding: '8px 12px',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 2,
        fontSize: 12,
        fontWeight: 600
    },
    noHistoryWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    noHistory: {
      width: '100%',
      maxWidth: 260,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    noHistoryText: {
      color: theme.palette.grey[400]
    },
    download: {
        minWidth: 36
    }
}));

const ProfileTransactionHistory = props => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();

    
    const [error, setError] = useState(null);
    const [waiting, setWaiting] = useState(false);

    const [dates, setDates] = useState({
        from: new Date(new Date().setDate(new Date().getDate()-30)),
        to: new Date()
    })

    const [data, setData] = useState([]);
    const [dateKeys, setDateKeys] = useState([])

    const [resendReceipt, setResendReceipt] = useState(null);

    const [downloading, setDownloading] = useState(false);

    const loadTransactions = async () => {
        setWaiting(true);
        setError(null);

        try {

            const fetchTransactions = await API.req('/wallet/transactions', 'GET', dates);
            if(fetchTransactions.success)
                setData(fetchTransactions.result);
            else
                throw fetchTransactions.msg;

            
        } catch (error) {
            setError(error);
        } finally {
            setWaiting(false);
        }
    }

    useEffect(() => {
        loadTransactions()
    }, [])

    useEffect(() => {
      createDateKeys()
    }, [data])
  
  
    const createDateKeys = () => {
  
      let newKeys = [];
      data.map(d => {
  
        let dateKey = SQLtoUTCDate(d.transaction_timestamp, false);
        if(newKeys.indexOf(dateKey) == -1)
          newKeys.push(dateKey);
  
      })
      setDateKeys(newKeys);
  
    }

    const dataByKey = (key) => {
      return data.filter(d => SQLtoUTCDate(d.transaction_timestamp, false) === key)
    }

    const transactionDescription = row => {
        if(row.card_type !== null)
            if(row.id_transaction_type === 3)
                return `REFUND - ${row.card_type} **${row.last_4digits}`
            else
                return `${row.card_type} **${row.last_4digits}`
        else
            return row.transaction_reference.slice(0, 30) + (row.transaction_reference.length > 18 && '...')
    }


    const downloadTransactions = async () => {
        setError(null);
        setDownloading(true);
  
        try {
  
            const fetchServices = await API.fileDownload('/wallet/transactions/download', 'GET', dates);
            // The native wrapper handles downloads
            if(process.env.REACT_APP_NATIVE != 'true'){
                const url = window.URL.createObjectURL(new Blob([fetchServices.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `BerthVend_${new Date().getTime()}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
  
        } catch (error) {
  
            console.log(error);
            setError(typeof error === 'string' ? t(error) : t('ERR'));
        } finally {
          setDownloading(false);
        }
  
    }

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inner}>
                    {error && <Alert severity="error">{t(error)}</Alert>}


                    <DatePickerToolbar onChange={loadTransactions} dates={dates} setDates={setDates}>
                        <Button variant="contained" size="small" onClick={downloadTransactions} classes={{root: classes.download}} disabled={downloading}>
                            {downloading && <CircularProgress size={16} color="inherit" />}
                            {!downloading && <img src={images.Icons_Download_Icon_White} style={{height: 16}} />}
                        </Button>
                    </DatePickerToolbar>

                    {waiting && 
                        <Table size="small">
                            <TableBody>
                                {[1,2,3,4,5].map((row, idx) => (
                                    <TableRow key={idx}>
                                        <TableCell><Skeleton variant="rect" className={classes.skeleton} style={{width: 100}} /></TableCell>
                                        <TableCell><Skeleton variant="rect" className={classes.skeleton} style={{width: 60}} /></TableCell>
                                        <TableCell><Skeleton variant="rect" className={classes.skeleton} style={{width: 46}} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    }

                    {!waiting &&

                        dateKeys.map((key, idx) => (
                                    
                            <React.Fragment key={idx}>

                                <div className={classes.dataKey}>{key}</div>
                                <Table size="small">
                                    <TableBody>
                                        {dataByKey(key).map((row, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell style={{flex: 1}}><Typography variant="caption">{transactionDescription(row)}</Typography></TableCell>
                                                <TableCell align="right"><Typography variant="caption"><b>{localeCurrency(row.amount, row.currency)}</b></Typography></TableCell>
                                                <TableCell align="right" style={{width: 46}}>{row.id_transaction_type === 1 && <IconButton onClick={() => setResendReceipt(row)} size="small" color="primary"><EmailIcon fontSize="small" /></IconButton>}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                            </React.Fragment>

                        ))

                    }

                    {!waiting && data.length === 0 && 
                        <div className={classes.noHistoryWrapper}>
                            <div className={classes.noHistory}>
                                <Typography className={classes.noHistoryText}>{t('NO_TRANSACTIONS')}</Typography>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <SlideOverDialog show={resendReceipt !== null} handleClose={() => setResendReceipt(null)} title={t('RESEND_RECEIPT')}><ProfileTransactionResendReceipt transaction={resendReceipt} handleClose={() => setResendReceipt(null)} /></SlideOverDialog>
        </React.Fragment>
    )


}

export default ProfileTransactionHistory;