import React, {useContext, useState} from 'react';
import { useTheme, Typography, makeStyles, CircularProgress, Paper, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import globalContext from '../context/globalContext';
import Skeleton from '@material-ui/lab/Skeleton';
import images from '../assets/imgs';
import { localeCurrency } from '../global/helpers'
import RefreshIcon from '@material-ui/icons/Refresh';
import SlideOverDialog from '../components/slideOverDialog';
import ServiceOngoing from './serviceOngoing';

const useStyles = makeStyles(theme => ({
    noServices: {
        marginTop: 10,
        backgroundColor: '#ffffff',
        padding: '8px 14px',
        borderRadius: 12,
        color: theme.palette.grey[600],
        textAlign: 'center'
    },
    loadingRect: {
        marginTop: 10,
        flex: 1,
        height: 180,
        borderRadius: 12,
        textAlign: 'center'
    },
    service: {
        flex: .5,
        marginTop: 10
    },
    serviceInner: {
        padding: 12
    },
    serviceIcon: {
        height: 20, 
        width: 'auto', 
        marginRight: 10
    },
    serviceMeter: {
        height: 80,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center'
    },
    serviceMeterValue: {
        fontWeight: 600
    },
    serviceMeterUnit: {
        fontWeight: 600,
        fontSize: 12
    }
}));

const CurrentServices = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const context = useContext(globalContext);
    const {t} = useTranslation();

    const [showServiceId, setShowServiceId] = useState(null);


    const handleServiceEnded = () => {
        setShowServiceId(null);
        context.servicesLoad();
    }

    const getMeterDisplay = service => {
        switch(parseInt(service.id_unit)){
            case 1:
            case 2:
                return (parseFloat(service.meter)/1000).toFixed(2).toString()
            default:
                return parseFloat(service.meter).toFixed(2).toString()
        }
    }


    const getServiceTitle = () => {

        if(showServiceId !== null){
            const findService = context.services.data.find(s => s.id_service === showServiceId);
            if(typeof findService !== 'undefined' && findService.utility.breakerOk === false)
                return (<div className="flex-row-start gap-0 align-center">{t('SERVICE')}<img src={images.Icons_Warning_Icon} alt="Service fault" style={{width: 24, marginLeft: 6}} /></div>)
        }
        return t('SERVICE');

    }

    return (
        <React.Fragment>
            <div className="p-b">
                <div className="flex-row align-center">
                    <Typography variant="subtitle2">{t('CURRENT_SERVICES')}</Typography>
                    <IconButton onClick={() => {if(context.services.status !== 'LOADING') context.servicesLoad()}}>{context.services.status !== 'LOADING' ? <RefreshIcon color="primary" style={{fontSize: 18}} /> : <CircularProgress size={18} />}</IconButton>
                </div>

                {(context.services.status === 'LOADING' && context.services.data.length === 0) && 
                    <div className="flex-row">
                        <Skeleton variant="rect" className={classes.loadingRect} />
                        <Skeleton variant="rect" className={classes.loadingRect} />
                    </div>
                }

                {(context.services.status !== 'LOADING' && context.services.data.length === 0) &&
                    <div className={classes.noServices}>
                        <Typography variant="body2">{t('NO_ASSIGNED_SERVICES')}</Typography>
                    </div>
                }

                {context.services.data.length > 0 &&
                    <div className="flex-row flex-wrap">
                        {context.services.data.map((s, idx) => (
                            <Paper key={idx} className={classes.service} onClick={() => setShowServiceId(s.id_service)}>
                                <div className={classes.serviceInner}>

                                    <div className="flex-row align-center" style={{marginBottom: 5}}>
                                        <div className="flex-row-start gap-0 align-center">
                                            <img src={parseInt(s.utility.id_utility_type) === 2 ? images.Icons_Water_Icon : images.Icons_Electric_Icon} className={classes.serviceIcon} alt='Utility type' />
                                            <Typography color="primary" variant="body1">{parseInt(s.utility.id_utility_type) === 2 ? t('WATER') : t('ELECTRICITY')}</Typography>
                                        </div>
                                        {(s.utility !== null && s.utility.breakerOk === false) && <img src={images.Icons_Warning_Icon} alt="Service fault" style={{width: 24}} />}
                                    </div>
                                    <Typography variant="caption" color="textSecondary">{s.identity}</Typography>

                                    <div className={classes.serviceMeter}>
                                        <div>
                                            <span className={classes.serviceMeterValue} style={{fontSize: (58-(4*getMeterDisplay(s).length))}}>{getMeterDisplay(s)}</span>
                                            <span className={classes.serviceMeterUnit}>{s.unit}</span>
                                        </div>
                                    </div>

                                    <div className="flex-col align-center gap-0">
                                        <Typography variant="caption">{t('STATUS')}: <b>{Boolean(s.paused) ? 'Off' : 'On'}</b></Typography>
                                        <Typography variant="caption">{t('TARIFF')}: <b>{localeCurrency(s.rate, s.currency)}</b></Typography>
                                    </div>

                                </div>
                            </Paper>
                        ))}
                    </div>
                }

            </div>


            <SlideOverDialog show={showServiceId !== null} handleClose={() => setShowServiceId(null)} title={getServiceTitle()} titleVariant="h6" color="primary"><ServiceOngoing id_service={showServiceId} handleEndSuccess={handleServiceEnded} /></SlideOverDialog>
        </React.Fragment>
    )
}
export default CurrentServices;