import React, {useContext, useState} from 'react';
import {makeStyles, Typography, useTheme, CircularProgress, Paper} from '@material-ui/core';
import globalContext from '../context/globalContext';
import { useTranslation } from 'react-i18next';
import images from '../assets/imgs';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import SlideOverDialog from '../components/slideOverDialog';

const useStyles = makeStyles(theme => ({
  heading: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 25
  },
  title: {
    marginBottom: 0,
    fontWeight: 400
  },
  subtitle: {
    marginBottom: 15,
    fontWeight: 400,
    maxWidth: 270
  },
  marinasWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20,
    gap: '20px'
  },
  noMarinasWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noMarinas: {
    width: '100%',
    maxWidth: 260,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noMarinasImage: {
    width: '100%',
    height: 'auto',
    maxWidth: 120
  },
  noMarinasText: {
    color: theme.palette.grey[400]
  },
  marina: {
    padding: theme.spacing(2)
  }
}))

const Marinas = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(globalContext);

  const {t} = useTranslation();

  const [organisation, setOrganisation] = useState(null);

  return (

    <React.Fragment>

      <div className={classes.heading}>
        <Typography variant="h5" color="primary" className={classes.title}>{t('MARINAS')}</Typography>
        <Typography variant="subtitle2" className={classes.subtitle}>{t('MARINAS_SUBTITLE')}</Typography>
      </div>

      <div className={classes.marinasWrapper}>

        {context.organisations.data.length === 0 && 
          <div className={classes.noMarinasWrapper}>
            <div className={classes.noMarinas}>
              {context.organisations.status === 'loading' &&
                <React.Fragment>
                  <CircularProgress size={24} color="default" />
                  <Typography className={classes.noMarinasText}>{t('LOADING')}</Typography>
                </React.Fragment>
              }

              {context.organisations.status !== 'loading' &&
                <React.Fragment>
                  <img src={images.Icons_Marina_LightGrey_Icon} alt="No marinas" className={classes.noMarinasImage} />
                  <Typography className={classes.noMarinasText}>{t('NO_PREVIOUSLY_USED_MARINAS')}</Typography>
                </React.Fragment>
              }
            </div>
          </div>
        }

        {context.organisations.data.map((o, idx) => (
          <Paper key={idx} className={classes.marina} elevation={3} onClick={() => setOrganisation(o)}>
            <div className="flex-row">
              <Typography variant="subtitle2" color="primary" style={{marginBottom: 10}}>{o.organisation_name}</Typography>
              <InfoIcon style={{color: theme.palette.primary.main, fontSize: 18}} />
            </div>
            <Typography variant="subtitle2">{t('ADDRESS')}</Typography>
            <Typography variant="body2">{o.organisation_address}</Typography>
          </Paper>
        ))}


      </div>


      <SlideOverDialog show={organisation !== null} title={organisation !== null ? organisation.organisation_name : ''} titleVariant="h6" handleClose={() => setOrganisation(null)} color="primary">

          {organisation !== null &&
            <React.Fragment>

              <div className="p-b">
                <Typography variant="subtitle2">{t('ADDRESS')}</Typography>
                <Typography variant="body2">{organisation.organisation_address || t('NO_ADDRESS')}</Typography>
              </div>

              <div className="p-b">
                <Typography variant="subtitle2">{t('PHONE')}</Typography>
                <Typography variant="body2">{organisation.organisation_phone || t('NO_PHONE')}</Typography>
              </div>

              <div className="p-b">
                <Typography variant="subtitle2">{t('EMAIL')}</Typography>
                <Typography variant="body2">{organisation.organisation_email || t('NO_EMAIL')}</Typography>
              </div>

            </React.Fragment>
          }

      </SlideOverDialog>

    </React.Fragment>

  );
}

export default Marinas;
